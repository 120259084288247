import React from 'react'
import { Container } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const RecipeDetailBlock = (props) => {
    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 2,
      };
    return (
        <Container style={{ textAlign:'center ', borderRadius:'30px' }}>
            <div style={{backgroundColor:'#1b3942', borderRadius:'30px'}}>
            <p style={{color: '#c8cac0', fontSize: '1.7rem', paddingTop:'20px'}} >{props.title}</p>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Slider {...settings}>
                    <div dangerouslySetInnerHTML={{__html: props.text}}>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: props.details}}></div>
                </Slider>
                </div>
            </div>
        </Container>
    )
}

export default RecipeDetailBlock