import React from 'react'
import OfficePhoto from '../Images/office.jpg'
import OfficePhoto2 from '../Images/office2.jpg'
import Links from '../common/links'

const Office = () => {
    return (
        <div style={{ height: '100VH', scrollSnapAlign: 'start' }} >
            <div style={{ display: 'flex', height: '100VH', flexWrap: 'wrap' }} >
            <div style={{ width: '30VW', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: '1', marginRight: '20px', marginLeft: '20px', minWidth: '240px', gap:'9%' }}>
                    <p style={{ fontSize: '2.5rem', padding: '5px', textAlign: 'center', margin: '0 20px', minWidth: '240px', lineHeight:'100%' }} className="main">Tvoj partner za izbalansiranu ishranu</p>
                    <img src={OfficePhoto2} className='officePic2 hide-element-on-small' alt=''/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', gap:'5%',flexGrow: '3'}}>
                    <img src={OfficePhoto} className='officePic' alt='' />
                    <img src={OfficePhoto2} className='officePic2 hide-element-on-big' alt=''/>
                    <div style={{ display: 'flex', flexWrap:'wrap', marginBottom: '10px' }}> <Links></Links>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Office