import React, { Component } from 'react'
import Header from '../common/header'
import MapSection from './map'
import Links from '../common/links'

class Contact extends Component {
    render() {
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header></Header>
                <h1 style={{ color: '#c8cac0', fontSize: '4rem', marginBottom:'24px' }} className="main">Kontaktiraj me</h1>
                <div style={{ display: 'flex', flexWrap:'wrap', justifyContent:'center', marginBottom:'20px' ,marginRight:'20px'}}><Links></Links></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', borderRadius: '25px'}}>
                    <MapSection location={location} zoomLevel={19} />
                </div>
            </div>
        )
    }
}

const location = {
    address: 'Janka Veselinovica 63 Kragujevac',
    lat: 44.01819,
    lng: 20.91353,
  }

export default Contact