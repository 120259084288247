import * as React from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

function HeaderMenu(props) {
    const navigate = useNavigate();

    return (
        <Container style={{ display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/biography')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Biografija</p>
            </Button>

            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/faq')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Pitanja</p>
            </Button>

            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/testimonials')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Iskustva</p>
            </Button>

            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/service')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Usluge</p>
            </Button>

            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/science')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Blog</p>
            </Button>

            <Button variant="outlined" style={{ borderColor: '#d67729', height: '6vH', minWidth: '8vw', maxWidth: '12vw', gap: '10px', display: 'flex', alignItems: 'center' }} onClick={() => navigate('/contact')}>
                <p style={{ color: '#d67729', fontFamily: 'main', fontSize: '20px', margin: 0 }}>Kontakt</p>
            </Button>
        </Container>
    );
}

export default HeaderMenu;
