import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from "@mui/material/Container";

const blogPostData = [
  { 
    image: require("./blog_post_small_krvne_grupe.jpg"), 
    title: "Dijeta na osnovu krvnih grupa",
    id: 1 
  },
  { 
    image: require("./blog_post_small_intermittent.jpg"),
    title: "Periodično gladovanje",
    id: 2
  },
  { 
    image: require("./blog_post_small_keto.jpg"), 
    title: "Keto dijeta",
    id: 3
  },
];

const BlogCarousel = () => {
  const navigate = useNavigate();
  window.scrollTo(0, 0);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px', paddingBottom:'10px' }}>
      <p style={{ color: 'white', fontSize: '2.5rem', textAlign:'center' }}>Još zanimljivih tema:</p>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
        {blogPostData.map((post) => (
          <div
            key={post.id}
            style={{display: 'flex', flexDirection: 'column', maxWidth: '320px',cursor: 'pointer',border: '1px solid #ccc', borderRadius: '25px'}}
            onClick={() => navigate(`/science/blog_post/${post.id}`)}
          >
            <img style={{ objectFit: 'cover', borderTopLeftRadius:'25px', borderTopRightRadius:'25px' }} src={post.image} alt={post.title} />
            <div style={{ backgroundColor: 'white', padding: '10px', borderBottomLeftRadius:'25px', borderBottomRightRadius:'25px'}}>
              <p style={{ color: 'black', fontSize: '1.5rem', textAlign:'center'}} className="text"><b>{post.title}</b></p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default BlogCarousel;