import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';
import FirstImage from '../blog_images/intermittent/img1.jpg'
import SecondImage from '../blog_images/intermittent/img2.jpg'
import ThirdImage from '../blog_images/intermittent/img3.jpg'
import FourthImage from '../blog_images/intermittent/img4.jpg'
import FifthImage from '../blog_images/intermittent/img5.jpg'
import SixthImage from '../blog_images/intermittent/img6.jpg'
import SeventhImage from '../blog_images/intermittent/img7.jpg'
import EighthImage from '../blog_images/intermittent/img8.jpg'
import NinethImage from '../blog_images/intermittent/img9.jpg'
import TenthImage from '../blog_images/intermittent/img10.jpg'
import EleventhImage from '../blog_images/intermittent/img11.jpg'
import TwelfthImage from '../blog_images/intermittent/img12.jpg'
import ThirteenthImage from '../blog_images/intermittent/img13.jpg'

const BlogPost3 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere'}}>
<p>Intermittent fasting ili periodično gladovanje je režim ishrane koji je popularan već godinama. Ljudi se najče&scaron;će odlučuju za ovaj režim kako bi postigli bolju kontrolu nad svojom telesnom težinom ili dobili neke od zdravstvenih benefita. U ostatku teksta možete pročitati o tome &scaron;ta je periodično gladovanje, kakvi različiti pristupi postoje, da li je zdravo i kakve rezultate daje.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Periodično gladovanje podrazumeva veliki broj različitih režima ishrane kojima je zajedničko da se hrana ne unosi tokom nekog vremenskog perioda u toku dana, nedelje ili meseca. Vremenski periodi tokom kojih se hrana ne unosi, u zavisnosti od režima, najče&scaron;će traju između 12 i 48 sati, ali postoje i oni koji podrazumevaju duže ili kraće preriode gladovanja.</p>
<p>Ideja iza periodičnog gladovanja je stavljanje tela u stanje pojačanog stresa, kada ono usled nedostatka ili niskog kalorijskog unosa koristi uskladi&scaron;tene izvore energije, poput masti. Dok stanje pojačanog stresa zvuči kao ne&scaron;to &scaron;to bismo želeli da izbegnemo, na&scaron;e telo ima potrebu za ovakvom vrstom stanja kako bi pokrenulo mehanizme odgovora na stres, koji ne bivaju pokrenuti svakodnevnim &sbquo;&sbquo;malim&rsquo;&rsquo; stresom. Veruje se da se kao odgovor na periodično gladovanje pokreće imuni odgovor tela koji za posledicu ima popravku o&scaron;tećenih ćelija i izbacivanje otpadnih produkata metabolizma koji u telu zaostaju kao posledica modernog načina života.</p>
<div className="conclusion"><p className='conclusion_content'>Intermittent fasting, kao režim ishrane sa periodima gladovanja, može dovesti do gubitka telesne težine i pokrenuti imuni odgovor tela koji doprinosi popravci ćelija i izbacivanju otpadnih produkata metabolizma.</p></div>
<h2>Vrste periodičnog gladovanja</h2>
<p>Iako postoje velike razlike u tipovima periodičnog gladovanja, svi imaju isti cilj i postižu slične rezultate. Za odabir pravog tipa periodičnog gladovanja za Vas potražite stručnu pomoć i razmislite o tome kojeg ćete moći najduže da se pridržavate po&scaron;to se značajni rezultati primećuju tek nakon vi&scaron;e od osam nedelja.</p>
<SquareImage src={SecondImage} alt="Description">
<h2>16/8</h2>
<p>Ovo je, iz mog iskustva, najče&scaron;ći i najpopularniji tip periodičnog gladovanja. Puno ljudi je čulo ili čak probalo ovaj režim u poku&scaron;aju regulisanja telesne težine. Podrazumeva relativno uzak vremenski okvir za unos hrane od osam sati, čemu sledi &scaron;esnaest sati bez kalorijskog unosa. Uglavnom ne podrazumeva bilo kakvo ograničenje namirnica ili kalorija tokom osam sati unutar kojih je unos hrane dozvoljen.</p>
<p>Rana i odložena 16/8 dijeta su dva oblika koja se razlikuju po tome da li se obrok pomera ili preskače. Kod rane, okvir od osam sati počinje gotovo odmah nakon buđenja sa doručkom. Sa druge strane, kod odložene doručak se u potpunosti preskače i okvir za hranjenje počinje prvim obokom koji je vremenski bliži ručku. Dok su za odabir idealnog načina ishrane ključne obaveze i navike ljudi, <a href="https://pubmed.ncbi.nlm.nih.gov/29195759/">postoje istraživanja</a> koja ukazuju da obroci u ranijem delu dana imaju vi&scaron;e benefita.</p>
<p><em>Težina: Srednje</em></p>
</SquareImage>
<h2>12/12 ili noćno gladovanje</h2>
<p>Dvanaest sati tokom kojih se jede i dvanaest sati tokom kojih se ne jede nije dijeta već očekivano pona&scaron;anje kojeg bi svi trebalo prirodno da se pridržavaju. Međutim, iznenadio sam se brojem ljudi sa kojima sam radio i razgovarao koji hranu unose tokom čitavog budnog dela dana, odnosno u okviru od 14, pa čak i 16 sati.</p>
<p>Ovo je jedan od prvih koraka koji predlažem ljudima sa problemima u kontroli telesne težine. Kada počnete sa doručkom, pogledajte na sat, zapamtite ili zapi&scaron;ite koliko je sati i do tog vremena uveče zavr&scaron;ite sa večerom. Nakon toga nemojte da unosite ni&scaron;ta osim vode ili čaja bez zaslađivača. Na ovaj način dajete telu dovoljno vremena da hranu svari pre spavanja i postižete bolji san. 12 sati bez unosa hrane će postići neke od efekata periodičnog gladovanja, posebno ako se ovoga pridržavate barem 6 dana nedeljno. Jo&scaron; jedna dobra stvar je da ćete sa 12/12 režima lak&scaron;e preći na neku ishranu sa dužim periodom gladovanja, npr. 14/10 režim.&nbsp;</p>
<p><em>Težina: Lako</em></p>
<h2>18/6 i 20/4</h2>
<p>Ovo su režimi sa malo intenzivnijim pristupom, pri čemu je vremenski okvir za kalorijski unos 6 ili 4 sata. Efekti ovih režima ishrane su identični ostalima iz grupe vremenski ograničenog hranjenja (16/8, 14/10, 12/12), ali su rezultati brže vidljivi i zahtevaju veću mentalnu disciplinu.</p>
<p>Ne preporučujem ih često zbog zabrinutosti od odustajanja, kao i ličnog verovanja da sporije postignuti efekti ishrane imaju dugotrajnije pozitivne rezultate.</p>
<p><em>Težina: Te&scaron;ko</em></p>
<h2>OMAD (engl. <strong>O</strong>ne <strong>M</strong>eal <strong>A</strong><strong>D</strong>ay)</h2>
<p>OMAD ili jedan obrok dnevno je najekstremniji pristup vremenski ograničenoj ishrani. Kao &scaron;to mu ime kaže, podrazumeva samo jedan obrok u toku dana koji traje oko sat vremena. Ovaj jedini obrok treba da zadovolji sve dnevne potrebe tela tako da mora da bude oblilat i raznovrsan. Ovakav režim ishrane idealan je za ljude koji imaju jako malo vremena da jedu kod kuće, kako im iz ishrane u potpunosti izbacuje brzu i nezdravu hranu koju bi inače jeli. Sa druge strane, zahteva disciplinu kao i puno odricanja, jer je pored hrane, van okvira od sat vremena dnevno, na ovom režimu zabranjen bilo kakv kalorijski unos, &scaron;to uključuje grickalice, voće, sokove, alkohol itd.</p>
<p><em>Težina: Veoma te&scaron;ko </em></p>
<SquareImage src={ThirdImage} alt="Description">
<h2>Spontano preskakanje obroka</h2>
<p>Ovo nije jasno strukturisan režim ishrane, već naziv za ono &scaron;to većina ljudi ponekad slučajno radi. Neki ljudi veruju da mogu postići benefite periodičnog gladovanja tako &scaron;to ukoliko nisu gladni ili nemaju vremena preskoče neki od obroka u toku dana.</p>
<p>Preskakanje obroka, posebno nasumično i neplanirano, može imati suprotan efekat i voditi prejedanju za vreme sledećeg obroka i unosu većeg broja kalorija. Spontano preskakanje obroka nije ne&scaron;to &scaron;to predlažem ljudima i smatram da postavljanje i držanje plana vodi boljim rezultatima. Treba spomenuti da preskakanje obroka može imati bolje rezultate od unosa hrane niskog kvaliteta, poput one iz pekare ili brze hrane.</p>
<p><em>Težina: Lako </em></p>
</SquareImage>
<SquareImage src={FourthImage} alt="Description">
<h2>5:2</h2>
<p>5:2 se odnosi na 2 dana posta i 5 dana normalne ishrane u toku nedelje. Ovaj režim ishrane, jedan je od najpopularnijih među svim režimima periodičnog gladovanja i relativno je prost za praćenje. U toku 5 dana normalne ishrane ne postoji računanje kalorija, niti odricanje od namirnica, dok se u toku 2 dana posta hrana ne izbacuje potpuno. U toku posta predviđa se unos 500 kalorija dnevno za žene, i 600 za mu&scaron;karce, a kalorije mogu biti unete u jednom ili vi&scaron;e obroka. Dodatno, dani za post ne moraju biti isti svake nedelje, pa ih možete odabrati na osnovu svojih obaveza, vodeći računa da nemate dva povezana dana posta.</p>
<p><em>Težina: Srednje</em></p>
</SquareImage>
<SquareImage src={FifthImage} alt="Description">
<h2>Ratnička dijeta</h2>
<p>Ova dijeta je osmi&scaron;ljena i popularizovana od strane fitnes eksperta i biv&scaron;eg vojnika izraelskih specijalnih snaga <strong><em>Ori Hofmeklera</em></strong>. Najsličnija je OMAD režimu, i predlaže jedan veliki obrok, uglavnom kasno u toku dana, tokom kojeg se može jesti bilo koja hrana. Međutim, u toku ranijeg dela dana, za razliku od OMAD-a, savetuje se količinski manji unos svežeg voća i povrća, kuvanih jaja ili mlečnih proizvoda.</p>
<p><a href="https://www.google.com/books/edition/The_Warrior_Diet/J_s3yTwPhDoC?hl=en&amp;gbpv=0">U svojoj knjizi</a> Ori obja&scaron;njava da se posmatrajući istorijske podatke i naučne studije može zaključiti da je za postizanje zdravlja i mr&scaron;avog, jakog tela najbolje opona&scaron;ati klasičan vojnički način života, poput onog koji su imali starogrčki i rimski ratnici. Ovakav način života podrazumeva puno fizičke aktivnosti sa malo hrane u toku dana, čemu usleđuje veliki obrok u toku večeri ili noći. Treba spomenuti da po njegovim rečima ovaj režim ishrane nije zasnovan na naučnim ili medicinskim istraživanjima već na njegovom ličnom posmatranju i uverenju.</p>
<p><em>Težina: Te&scaron;ko</em></p>
</SquareImage>
<SquareImage src={SixthImage} alt="Description">
<h2>Eat Stop Eat</h2>
<p>Eat Stop Eat bi se mogao prevesti kao Jedi Stani Jedi, a promovisan je u <a href="https://www.goodreads.com/book/show/36325908-eat-stop-eat">istoimenoj knjizi</a> od strane eksperta za fitnes <strong><em>Breda Pilona</em></strong>. Govori o periodu gladovanja u trajanju od 24 časa, jednom do dva puta nedeljno čemu usleđuju dani tokom kojih se jede normalno. Slična je 5:2 režimu, ali za razliku od njega, tokom dana postan nema nikakvog kalorijskog unosa. Popularana je zbog odsustva pravila danima kada se hrana unosi, mada je preporuka da se jede normalno, odnosno da se izbegava prejedanje nakon posta.</p>
<p><em>Težina: Te&scaron;ko</em></p>
</SquareImage>
<SquareImage src={SeventhImage} alt="Description">
<h2>Post naizmeničnim danima ili 4:3 režim</h2>
<p>Ovaj post postoji u dva oblika, ali oba podrazumevaju dan normalne i zdrave ishrane sa 3 do 5 obroka dnevno, čemu usleđuje dan posta. Razlika u varijacijama postoji u danu posta. Dok neki stručnjaci za ishranu predlažu unos do 500 kalorija ili oko 25% dnevnih potreba, drugi savetuju potpuni post, odnosno unos samo tečnosti bez kalorija na dan posta, poput kafe, čaja i vode.</p>
<p>Obavljena su brojna istraživanja koja su upoređivala efekte posta naizmeničnim danima u odnosu na klasične dijete sa svakodnevnim ograničenjem kalorija. Prema istraživanju koje je obuhvatilo <a href="https://www.frontiersin.org/articles/10.3389/fnut.2020.586036/full">veći broj manjih istraživanja</a> postoji značajna razlika u efikasnosti gubljenja telesne težine pri ova dva pristupa, i pokazalo se da post naizmeničnim danima daje bolje rezultate.</p>
<p>&nbsp;<em>Težina: Te&scaron;ko</em></p>
</SquareImage>
<SquareImage src={EighthImage} alt="Description">
<h2>Produženo gladovanje</h2>
<p>Produženo gladovanje je najzahtevniji oblik periodičnog gladovanja po&scaron;to podrazumeva periode bez kalorijskog unosa koji su duži od 24 časa. Najče&scaron;ći oblik je 48 sati posta, ali postoje ljudi koji praktikuju i režime u trajanju između 4 i 21 dan. Pored gladi, dodatni problem ovog režima predstavljaju vrtoglavica i umor koji se javljaju usled nedostatka ugljenih hidrata i elektrolita, tako da nije za svakoga.</p>
<p>Ovo je režim koji ja pratim, sa postovima koji traju između 33 i 48 sati, dva puta mesečno. Iz mog iskustva veoma je naporan prvih nekoliko puta, ali se telo nakon toga privikava i naredni postovi su mentalno i fizički lak&scaron;i. Dodatno, prona&scaron;ao sam vi&scaron;e trikova koji mogu pomoći, poput otpočinjanja posta nakon doručka prvog dana, konzumacije vode sa elektrolitima, čaja ili kafe u cilju odlaganja osećaja gladi. Za razliku od ostalih sličnih režima ne podrazumeva post svake nedelje već samo nekoliko puta mesečno &scaron;to meni vi&scaron;e odgovara.</p>
<p><em>Težina: Veoma te&scaron;ko</em></p>
</SquareImage>
<h2>Drugi oblici</h2>
<p>Pored navedenih protokola, moguće je naći druge režime i iskustva ljudi koji si ih isprobali. Postoje gotovo sve kombinacije do sada spomenutih režima uključujući spontano preskakanje vi&scaron;e obroka, plansko preskakanje obroka svakog drugog dana, praćenje 8/16 ili drugog vremenski ograničenog režima nekoliko puta nedeljno i drugi. Ponovio bih da ne postoje istaživanja koja ukazuju na veću ili manju efikasnost nekog od ovih načina ishrane, tako da je najbitnije odabrati onaj koji Vam najvi&scaron;e prija i kojeg se možete kontinuirano pridržavati &scaron;to duže.</p>
<h2>Benefiti</h2>
<h3>Autofagija</h3>
<p>Povezanost periodičng gladovanja i autofagije poznata je i ljudima koji nisu upoznati sa tačnim značenjem ovih termina. Istovremeno, autofagija je odgovorna za neke od potencijalnih zdravstvenih benefita periodičnog gladovanja, pa ću iz ovih razloga ukratko objasniti &scaron;ta je autofagija i za&scaron;to je interesantna.</p>
<p>Reč autofagija dolazi iz starogrčkog i može se prevesti kao jedenje samog sebe. Ovo je prirodni mehanizam kojim se uni&scaron;tavaju i recikliraju nefunkcionalni delovi ćelije. Nefunkcionalni delovi ćelije mogu nastati na razne načine pod uticajem spolja&scaron;njih faktora ili prirodnih mutacija.</p>
<p>Autofagija ili autofagocitoza de&scaron;ava se konstantno u na&scaron;em telu, ali može biti pojačana ili usporena. U periodima posta, kada nema novog kalorijskog unosa, telo se okreće traženju energije unutar sebe, tako da dolazi do autofagije pojačanog intenziteta. Dodatno, po&scaron;to telo nije opterećeno varenjem novounete hrane, ono se okreće či&scaron;ćenju i održavanju, tako da je gladovanje poput nedeljnog pospremanja kuće.</p>
<RectangleImage src= {NinethImage} alt="Description"></RectangleImage>
<p>Poslednjih nekoliko decenija autofagija je privukla dosta pažnje medicine, kako se <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6257056/">pokazalo</a> da ima pozitivan i/ili negativan uticaj na vi&scaron;e poremećaja, uključujući infekcije, zapaljenja, neurodegenerative, metaboličke i kardiovaskularne bolesti, gojaznost i različite tipove kancera.</p>
<p>Na žalost, povezanost autofagije sa različitim bolestima je izuzetno komplikovana, i jo&scaron; uvek ne postoje jasni podaci o njenom tačnom uticaju. Na primer, <a href="https://pubmed.ncbi.nlm.nih.gov/27664235/">vi&scaron;e studija na životinjskim modelima</a> dokazalo je ulogu autofagije u sprečavanju nastanka ćelija tumora, kroz za&scaron;titu ćelija od oksidativnog stresa, o&scaron;tećenja DNK, upala ili nakupljanja nefunkcionalnih organela u ćeliji. Međutim, isto nije dokazano kod ljudi, a dodatno je otkrivena ključna uloga autofagije u preživljavanju i progresiji tumornih ćelija, &scaron;to ima suprotne efekte.</p>
<p>Dok su dalja istaživanja apsolutno neophodna, <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6257056/#b63-cln_73p1">dokazano je</a> da autofagija pozitivno utiče na odgovor tela pri hemioterapiji, radioterapiji i ciljanoj terapiji koje se primenjuju tokom lečenja kancera, kroz za&scaron;titu normalnih ćelija od toksičnih efekta ovih agenata, zbog čega se autofagija, odnosno post razmatra kao pomoćna terapija pri lečenju tumora.</p>
<div className="conclusion"><p className='conclusion_content'>Autofagija je prirodan proces koji podrazumeva varenje lo&scaron;ih delova ćelije. Može imati pozitivan uticaj na prevenciju kancera, ali je neophodno jo&scaron; istraživanja.</p></div>
<p></p>
<SquareImageLeft src={TenthImage} alt="Description">
<h3>Gojaznost</h3>
<p><a href="https://pubmed.ncbi.nlm.nih.gov/28106818/">&Scaron;est manjih studija</a> upoređivalo je efekte periodičnog gladovanja u odnosu na standardnu dijetu sa ograničenjem kalorija i zaključilo da nema većih razlika u postignutim rezultatima. Treba istaći da su svi režimi periodičnog gladovanja uključivali zdravu ishranu tokom dana kada se ne posti, koja je podrazumevala ishranu zasnovanu na biljkama i sa niskim unosom &scaron;ećera.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7021351/">27 ispitivanja</a>, uglavnom kraćeg trajanja i sa manjim brojem ispitanika, ukazala su da periodično gladovanje može da bude efikasan način za kontrolu težine. Ispitanici su postigli gubitak težine između 0,8% i 13% u toku 2 do 26 nedelja trajanja istraživanja. Neka od ispitivanja, koja su istovremeno pratila i ljude na dijetama sa ograničenim kalorijskim unosom, potvrdila su da nema značajnih razlika u efikasnosti. Dok ovi podaci nisu potpuno pouzdani, ukazuju na obećavajuće efekte posta.</p>
</SquareImageLeft>
<Conclusion>Periodičnim gladovanjem mogu se postići potvrđeni rezultati pri poku&scaron;ajima smanjenja telesne težine, koji su slične efikasnosti kao i dijete sa svakodnevnim ograničenjem kalorija. </Conclusion>
<SquareImage src={EleventhImage} alt="Description">
<h3>Insulinska rezistencija i Dijabetes tipa 2</h3>
<p>Nivo glukoze u krvi u periodu gladovanja jedan je od glavnih pokazatelja poremećaja u metabolizmu ugljenih hidrata, odnosno insulinske rezistencije, koja je predstanje dijabetesa tipa 2 i značajan problem koji se javlja kod sve većeg broja ljudi u na&scaron;oj zemlji i svetu. Zbog toga, kontrola nivoa glukoze u krvi predstavlja jedan od ključnih zadataka koji se postavlja pred modernu medicinu. Nalaženje efikasnog načina za postizanja ove kontrole oblast je koja se aktivno razvija i istražuje.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6832593/">Osam odvojenih studija</a> prijavilo je učinkovitost periodičnog gladovanja u spu&scaron;tanju nivoa glukoze. &Scaron;est studija primenjivale su samo režime periodičnog gladovanja, dok su druge dve dodatno uključivale svakodnevni trening. Nije primećena veća razlika u ova dva pristupa, ali su oba pokazala značajno smanjenje nivoa glukoze u krvi pri gladovanju. </p>
<p><a href="https://pubmed.ncbi.nlm.nih.gov/33531076/">Pregled dostupne literature</a> iz februara 2020, u većini slučajeva potvrđena je efikasnost periodičnog gladovanja kao nemedicinskog pristupa pri lečenju dijabetesa tipa 2, snižavanju nivoa glukoze i insulina u krvi, kao i smanjenju insulinske i leptinske rezistencije.</p>
</SquareImage>
<div className="conclusion"><p className='conclusion_content'>Postoje čvrsti dokazi o pozitivnim efektima posta na nivoe glukoze i insulina u krvi.</p></div>
<h3>Kardiovaskularne bolesti</h3>
<p>Kardiovaskularne bolesti su vodeći uzročnik smrti u na&scaron;oj zemlji i svetu. Ovo je grupa bolesti srca i krvnih sudova čiji uzročnik nije jedinstven. <a href="https://www.nejm.org/doi/full/10.1056/nejmra1905136#:~:text=Evidence%20is%20accumulating%20that%20eating,diseases%2C%20including%20cancer%20and%20obesity">Periodično gladovanje</a> pobolj&scaron;ava vi&scaron;e faktora koji utiču na kardiovaskularne bolesti, &scaron;to je dokazano na životinjskom i ljudskom primeru. Periodično gladovanje pozitivno utiče na krvni pritisak, broj otkucaja srca, nivo holesterola, lipoproteina, triglicerida, kao i nivo već spomenutih glukoze i insulina.</p>
<div className="conclusion"><p className='conclusion_content'>Povremeno gladovanje pozitivno utiče na bolesti srca i krvnih sudova.</p></div>
<h3>Starenje, oksidativni stres i upalni procesi</h3>
<p>Povezanost periodičnog gladovanja sa starenjem ustanovljena je na ljudima i životinjama. Međutim, jasno obja&scaron;njenje ove povezanosti jo&scaron; uvek se traži. Jedan od predloga je da gladovanje uzrokuje produkciju protektivnih molekula koje na&scaron;e telo &scaron;tite od oksidativnog sresa, kao i smirivanje upalnih procesa koji vode bržem starenju tkiva. Sam proces starenja je veoma komplikovan i i&scaron;čekuju nas brojna nova otkrića, a povezanost povremenog gladovanja sa ovim procesima se intenzivno istražuje, ali istraživanja do kojih sam do&scaron;ao uključivala su veoma mali broj ispitanika ili kratke vremenske periode.</p>
<div className="conclusion"><p className='conclusion_content'>Post može imati pozitivne efekte na smanjenje upale i oksidativnog stresa, &scaron;to za posledicu može imati usporavanje starenja.</p></div>
<h3>Ostalo </h3>
<p><a href="https://www.nejm.org/doi/full/10.1056/nejmra1905136#:~:text=Evidence%20is%20accumulating%20that%20eating,diseases%2C%20including%20cancer%20and%20obesity">Postoje istraživanja</a> o pozitivnim efektima povremenog gladovanja na druga stanja i bolesti, a zasnovana su na uticaju posta na iste parametre koji su do sada već spomenuti. Smanjenje telesne težine, pobolj&scaron;anje krvnih parametara, smanjenje oksidativnog stresa i ublažavanje upalnih procesa može dovesti do pobolj&scaron;anja pri različitim bolestima ili stanjima poput kancera, neurodegenerativnih poremećaja, astme, multiple skleroze, artritisa, hirur&scaron;kih ili ishemijskih o&scaron;tećenja tkiva, psihičkih i kognitivnih poremećaja i dr. Dok je uticaj na neke od ovih bolesti ili stanja dobro istražen, za druge gotovo da nema istraživanja, a čitava oblast tek treba da bude primenjena u medicniskoj praksi.</p>
<div className="conclusion"><p className='conclusion_content'>Uticaj i primena posta pri lečenju brojnih bolesti tek treba da bude istražena.</p></div>
<SquareImage src={TwelfthImage} alt="Description">
<h3>Bojazni</h3>
<p>Česta briga ljudi koji su skeptični prema periodičnom gladovanju je razgradnja mi&scaron;ića usled nedovoljnog kalorijskog i proteinskog unosa. Međutim, ovo se ne de&scaron;ava po&scaron;to ne postoji dug vremenski period sa restrikcijom unosa hrane. Tokom perioda posta može doći do razgradnje manjeg procenta mi&scaron;ićne mase, ali se uporedno pojačava lučenje hormona rasta. Kada proteini ponovo budu dostupni, hormon rasta usmerava proteine na izgradnju mi&scaron;ića koji su se potro&scaron;ili u toku posta, tako da ne dolazi do trajnog gubitka mi&scaron;ićne mase. <a href="https://academic.oup.com/fampra/article/16/2/196/480196">Studije</a> ukazuju da je mi&scaron;ićna masa održana pri praktikovanju povremenog gladovanja, ali ne zaključuju da li je na ovom režimu moguće značajno povećati mi&scaron;ićnu masu. </p>
<p>Usporavanje bazalnog metabolizma je druga česta bojzan. Usporavanje bazalnog metabolizma praktično znači u nedostatku energije telo počinje da tro&scaron;i manje. Pri hroničnom gladovanju koje traje vi&scaron;e desetina dana, smanjena potro&scaron;nja energije može se zadržati godinama pa čak i decenijama. Ovakva metabolička promena bila bi posebno nepoželjna za ljude koji poku&scaron;avaju da smr&scaron;aju praktikovanjem periodičnog gladovanja. Međutim, prema velikom broju radova, ovo se zasigurno ne de&scaron;ava pri kratkoročnim gladovanjima do tri dana.</p>
</SquareImage>
<div className="conclusion"><p className='conclusion_content'>Strah nekih ljudi od gubitka mi&scaron;ićne mase ili usporavanja bazalnog metabolizma nije naučno potkrepljen.</p></div>
<h3>Sporedni efekti</h3>
<p>Pri svakoj promeni načina ishrane mogu se javiti neki problemi i sporedni efekti, &scaron;to je slučaj i sa periodičnim gladovanjem. Najče&scaron;ći i očekivani sporedni efekat je glad koja može uzrokovati prejedanje nakon posta. Drugi sporedni efekti mogu biti glavobolja i vrtoglavica, nervoza i nadražljivost, problemi sa varenjem i stolicom, umor i gubitak energije, problemi sa snom i zadahom, i dehidracija. Većina ovih problema može biti preventovana adekvatnim unosom elektrolita i tečnosti tokom posta, i postepenim uvođenjem hrane nakon njega.</p>
<div className="conclusion"><p className='conclusion_content'>Periodično gladovanje može izazvati prolazne nuspojave, ali većina se može ublažiti adekvatnom hidratacijom i postepenim uvođenjem hrane.</p></div>
<h3>Rizici</h3>
<p>Periodično gladovanje može dovesti do povećanja telesne težine ukoliko postu usledi veliki kalorijski unos tokom dana kada je unos hrane dozvoljen. Iz ovog razloga treba dobro isplanirati i ograničiti obroke. Dok uglavnom nemam problema sa prejedanjem, vi&scaron;e puta sam napravio gre&scaron;ku i nakon posta na sto izneo praktično sve iz frižidera, a malo u njega vratio. Ovo je uzrokovalo osećaj nadutosti, mučnine i presitosti. Iz ovog razloga prvi obrok nakon posta sastoji mi se od oko 400 grama kuvanih pilećih grudi, a drugi obrok konzumiram tek 2 do 3 sata nakon toga.</p>
<SquareImageLeft src={ThirteenthImage} alt="Description">
<p>Ukoliko patite ili ste patili od poremećaja ishrane, poput bulimije ili anoreksije, periodično gladovanje nije za Vas. Bilo koja dijeta koja uključuje preskakanje obroka može izazvati poremećaj u odnosu sa hranom i dovesti do poremećaja ishrane.</p>
<p>Ljudi koji pate od dijabetesa tipa 1 ili uzimaju lekove za dijabetes tipa 2 ne bi trebalo da praktikuju duže postove, po&scaron;to može doći do opasnog smanjenja nivoa glukoze u krvi.</p>
<p>Žene koje su u drugom stanju ili doje ne bi trebale da se podvrgavaju restriktivnim režimima ishrane zbog povećanih energetskih potreba i potencijalnih rizika po plod.</p>
</SquareImageLeft>
<p>Različita medicinska stanja, poput temperature, skore operacije ili drugih bolesti, kao i psihičke bolesti u kombinaciji sa restriktivnim režimima ishrane mogu predstavljati dodatni rizik i nisu preporučljive.</p>
<div className="conclusion"><p className='conclusion_content'>Iako periodično gladovanje može biti korisno za kontrolu telesne težine, važno je biti oprezan i izbegavati ga u slučaju određenih zdravstvenih stanja ili rizika od poremećaja u ishrani.</p></div>
<h2>Zaključak </h2>
<p>Neki oblik posta postoji vi&scaron;e od tri hiljade godina kao deo religijske ili socijalne prakse. Ovome je prethodio vi&scaron;emilenijski post uzrokovan okruženjem i nedostatkom hrane, tako da periodi bez unosa hrane postoje od kada postoji i čovek. Mi smo prva ili druga generacija ljudi ikada, koja ima luksuz svakodnevne hrane na tanjiru. Iz ovog razloga ne čudi da smo evolutivno razvili sposobnost preživljavanja bez unosa kalorija u dužim vremenskim periodima. Dok je periodično gladovanje i njegov potencijalni pozitivan uticaj poznat već vi&scaron;e decenija, i dalje nemamo razvijene navike ili propisane procedure za praktikovanje ove vrste posta.</p>
<p>Začuđujuće je koliko je ljudi sa kojima sam razgovarao i radio izjavilo kako oni nikad ne bi mogli da prežive ceo dan, a da ne jedu. Moje mi&scaron;ljenje je da bi svi trebalo da se pridržavaju nekog od ovde nabrojanih režima ishrane kako bi telu dali vremena za či&scaron;ćenje i postigli duži i zdraviji život.</p>
<p>Neki od pomenutih režima su teži od drugih, ali težina nikako ne govori o efikasnosti, niti benefitima koji se mogu postići. Treba se fokusirati na odabir režima koji Vam najvi&scaron;e prija i kojeg se možete pridržavati praktično ceo život.</p>
<p>Interesantno je da, dok većina režima, načelno, podrazumeva svakodnevno pridržavanje, istraživanja govore da to nije neophodno. Ukoliko ste iza&scaron;li u grad, oti&scaron;li na proslavu ili samo jako poželeli da danas jedete izvan propisanog vremenskog okvira, to nije problem. Efekti ostaju nepromenjeni dokle god ne kr&scaron;ite pravila če&scaron;će od dva neuzastopna dana nedeljno. Nakon dana kada niste mogli ili želeli da ispo&scaron;tujete odabrani režim, samo nastavite kao da se ni&scaron;ta nije desilo.</p>
<p>Kao i uvek, stojim Vam na raspolaganju za sva pitanja, savete ili sugestije, a o drugim temama možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost3