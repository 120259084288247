import * as React from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  { text: 'Biografija', route: '/biography' },
  // { text: 'Recepti', route: '/recipes' },
  {text: 'Pitanja', route: '/faq'},
  { text: 'Iskustva', route: '/testimonials' },
  {text: 'Usluge', route: '/service'},
  { text: 'Blog', route: '/science' },
  { text: 'Kontakt', route: '/contact' },
];

function Menu() {
  const navigate = useNavigate();

  return (
    <Container style={{backgroundColor: '#1b3942', height: '55vH', justifyContent: 'space-around', padding: '10%', alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
      {menuItems.map((item) => (
        <div key={item.route} style={{ minWidth: '200px', maxWidth: '280px', flexGrow: '1'}}>
          <Button style={{ backgroundColor: 'transparent', width: '100%', height: '45px' }} onClick={() => navigate(item.route)}>
            <p style={{ color: '#d67729', fontSize: '20px' }} className="text">{item.text}</p>
          </Button>
        </div>
      ))}
    </Container>
  );
}

export default Menu;