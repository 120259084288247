import React from 'react';

export function RectangleImage({ src, alt }) {
  return (
    <div style={{ textAlign: 'center' }}> {/* Use textAlign to center the image */}
      <img src={src} alt={alt} className="blogimage" />
    </div>
  );
}

export function SquareImage({ src, alt, children }) {
  return (
    <div className="blogimage-square-containter">
      <div>
        {children}
      </div>
      <img src={src} alt={alt} className="blogimage-square" />
    </div>
  );
}

export function SquareImageLeft({ src, alt, children }) {
  return (
    <div className="blogimage-square-containter">
      <img src={src} alt={alt} className="blogimage-square-left" />
      <div>
        {children}
      </div>
    </div>
  );
}

export function Conclusion({ children }) {
    return (
      <div className="conclusion">
        <p className="conclusion_content">
          {children}
        </p>
      </div>
    );
  }