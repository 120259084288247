import React, { useState } from 'react';
import { Button, Modal, Box } from '@mui/material';

function BookButton() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            {/* Prilagođeno dugme za zakazivanje */}
            <Button
                variant="contained"
                sx={{ border:'4px solid #ccc'}} style={{borderColor: '#d67729', height:'10vH', width:'auto', backgroundColor:'transparent' }}
                
                onClick={handleOpen} // Otvara modal
            >
                 <p style={{ fontSize: '30px', color: '#d67729', fontFamily:'main' }} ><b>ZAKAŽI SASTANAK</b></p>
            </Button>

            {/* Modal sa Google Calendar iframe */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ bgcolor: 'background.paper', p: 4, borderRadius: '8px', maxWidth: '100vw', width: '80%' }}>
                    <h2 id="modal-title"> </h2>
                    <iframe
                        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2GUx-8rOym82VZRVdmCJYhueSznvF14N-TgXNzB_psKRumvQZ3bFBMHUt8CMVwJFgZCPnWe_7y?gv=true"
                        style={{ border: '0', width: '100%', height: '600px' }}
                        frameBorder="0"
                        title="Google Calendar Booking"
                    ></iframe>
                </Box>
            </Modal>
        </div>
    );
}

export default BookButton;
