import React from 'react'
import FirstImage from '../blog_images/proteinski_suplementi/Protein_1.jpg'
import SecondImage from '../blog_images/proteinski_suplementi/Protein_2.jpg'
import ThirdImage from '../blog_images/proteinski_suplementi/Protein_3.jpg'
import FourthImage from '../blog_images/proteinski_suplementi/Protein_4.jpg'
import FifthImage from '../blog_images/proteinski_suplementi/Protein_5.jpg'
import SixthImage from '../blog_images/proteinski_suplementi/Protein_6.jpg'
import SeventhImage from '../blog_images/proteinski_suplementi/Protein_7.jpg'
import EightImage from '../blog_images/proteinski_suplementi/Protein_8.jpg'
import NinethImage from '../blog_images/proteinski_suplementi/Protein_9.jpg'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';

const BlogPost7 = () => {
    return (
        
        <div style={{width:'100VW', overflowWrap: 'anywhere', alignItems: 'center'}}>
                   
<p>Proteinski suplementi se često koriste među profesionalnim sportistima, kao i među fizički aktivnim rekreativcima, u cilju povećanja mi&scaron;ićne mase, pobolj&scaron;anja fizičkih performansi, povećanju količine energije, ubrzanog oporavka ili ubrzanog gubitka telesnih masti. Međutim, opravdanost kori&scaron;ćenja, učestalost, kao i količina proteinskih suplemenata koju treba unositi je pitanje koje mi često postavljaju klijenti. Dodatno, treneri često ljudima predlažu spulementaciju i smatraju je neophodnom za postizanje dobrih rezultata. Kada dobijate savete vezane za ishranu i suplementaciju, morate razmi&scaron;ljati o edukaciji ljudi koji vam te savete daju. Na žalost, čak i u profesionalnom sportu postoje brojna pogre&scaron;na mi&scaron;ljenja koja su posledica nerazumevanja procesa u na&scaron;em telu i nedovoljne informisanosti.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Cilj ovog teksta je da objasni &scaron;ta su proteini, koji proteinski suplementi su najbolji, kada i koliko ih treba unositi i ko ima potrebu za dodatnim unosom. Kratak odgovor je da ukoliko niste izuzetno aktivni (pod čime se podrazumevaju svakodnevni intenzivni treninzi) i imate normalnu raznovrsnu ishranu, koja uključuje meso, jaja, mleko, mlečne proizvode, ribu i sl. verovatno unosite dovoljno proteina i dodatna suplementacija vam nije potrebna. Sa druge strane, imamo jasne dokaze da pri povećanoj fizičkoj aktivnosti, dodatan unos proteina ima pozitivne efekte na optimizaciju treninga o čemu će u daljem tekstu biti vi&scaron;e reči.</p>
<p>Ne treba zaboraviti da proteini učestvuju u energetskom metabolizmu. Proteini se ugrađuju u mi&scaron;iće samo kao posledica treninga, dok svaki vi&scaron;ak proteina biva pretvoren u energiju, odnosno masti. Ukoliko Vam je cilj smanjenje udela masti u telesnoj težini, morate biti oprezni pri unosu proteina.</p>
<Conclusion>Potrebu za proteinskim sportskim suplementima imaju ljudi koji imaju intenzivnu sportsku aktivnost, a nemaju oni sa umerenom aktivno&scaron;ću i raznovrsnom ishranom.</Conclusion>
<h2 id= "sta-su-proteini">&Scaron;ta su proteini i za&scaron;to nam trebaju?</h2>
<p>Pored ugljenih hidrata i masti, proteini spadaju u makronutrijente, &scaron;to znači da je za normalno fuknkcionisanje tela neophodan svakodnevni unos. Proteini su izgrađeni od velikog broja manjih jedinica, amino kiselina, od kojih se neke proizvode u ljudskom telu, dok druge moraju biti unete hranom. Postoji 9 amino kiselina koje moraju biti unete hranom, one se nazivaju esencijalne po&scaron;to su neophodne za normalno funkcionisanje tela.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Pri konzumaciji proteina, bilo kroz hranu ili u vidu suplemenata, na&scaron;e telo gotovo u potpunosti rastavlja proteine na amino kiseline, koje dalje koristi za izgradnju sopstvenih proteina. Neophodno je da proteini koje unosimo imaju takv sastav da obezbeđuju na&scaron;em telu dovoljnu količinu svih esencijalnih amino kiselina, kako bi ono moglo da izgradi sve proteine koji su mu neophodni.</p>
<p>Proteini imaju izuzetno bitne funkcije u telu. Oni su neophodni za izgradnju i popravku svih tkiva u telu, učestvuju u odgovoru imunog sistema, balansiranju telesnih tečnosti, preno&scaron;enju poruka, održavanju pH vrednosti i dr.</p>
</SquareImage>
<Conclusion>Proteini učestvuju u svim reakcijama u telu, tako da bez njih praktično nema života.</Conclusion>
<h2 id= "koliko-proteina-treba-da-unosimo">Koliko proteina treba da unosimo?</h2>
<p>Ovo je pitanje oko kojeg postoje velike nesuglasice i dosta nerazumevanja. Svaki organizam je drugačiji &scaron;to znači da nam za normalno funkcionisanje nije potrebna ista količina proteina. Kada razmi&scaron;ljamo o neophodnim dnevnim potrebama, moramo uzeti u razmatranje namirnice koje će biti izvor proteina, količine koje su nam neophodne za održanje i povećanje mi&scaron;ićne mase, ali i količine koja je neophodna za normalno funkcionisanje tela. Postoji razumljiva razlika u preporučenim količinama proteina u zavisnosti od pola, telesne težine, procenta mi&scaron;ićne mase, aktivnosti individue i drugih faktora.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Preporučena količina (<u>RDA</u>) proteina za osobe starije od 18 godina, iznosi <strong>0,8 grama</strong> po kilogramu telesne težine dnevno. <a href="https://www.nap.edu/catalog/10490/dietary-reference-intakes-for-energy-carbohydrate-fiber-fat-fatty-acids-cholesterol-protein-and-amino-acids">Ova preporuka</a> izdata je od Instituta za medicinu (Sjedinjene Američke Države) i nije se menjala godinama. Autori <a href="https://pubmed.ncbi.nlm.nih.gov/18577734/">rada</a> izdatog od strane američke medicinske asocijacije dali su dodatno obja&scaron;njenje preporuke kao minimalnu količinu proteina koja je potrebna da bi se izbegao manjak proteina koji može uzrokovati gubitak mi&scaron;ićne mase. </p>
<p>Zbog nejasnoća vezanih za ovaj termin isti institut napravio je druge preporuke. Ukoliko se posmatra količina energije koju dobijamo iz masti, ugljenih hidrata i proteina, preporučeni unos proteina iznosi između 10% i 35% ukupne energije. Kada se ovo pretvori u grame za prosečnu potro&scaron;nju dobija se preporuka od <strong>1,05</strong> grama do <strong>3,67</strong> grama proteina dnevno po kilogramu telesne težine. Ova preporuka bolje oslilkava relane potrebe.</p>
</SquareImageLeft>
<p>Osim ovih, postoje i brojna druga mi&scaron;ljenja i preporuke. <a href="https://pubmed.ncbi.nlm.nih.gov/19841581/">Novija istraživanja</a> ukazuju da su čak i minimalne dnevne potrebe ne&scaron;to veće i da iznose između 1 i 1,2 grama po kilogramu telesne težine dnevno za zdrave, aktivne, mlađe osobe. Dok, <a href="https://pubmed.ncbi.nlm.nih.gov/26962173/">istraživanje</a> koje se bavilo potrebama za proteinima mu&scaron;karaca starijih od 65 godina ukazuje na jo&scaron; veće potrebe, između 1,2 i 1,4 grama po kilogramu telesne težine dnevno.</p>
<Conclusion>Količina proteina koju unosite svakoga dana treba da bude 1 do 2 grama po kilogramu telesne težine u zavisnosti od količine fizičke aktivnosti, procenta mi&scaron;ićne mase i dugih faktora.</Conclusion>
<h2 id="proteini-iz-hrane">Proteini iz hrane</h2>
<p>Proteinski suplementi potrebni su nam kada ne možemo dobiti dovoljno proteina iz hrane. Proteini se u većim ili manjim količinama nalaze u svoj hrani koju konzumiramo, tako da pre razmatranja suplementacije treba izračunati količinu proteina dobijenih iz namirnica. U narednom delu teksta biće izlistane neke od namirnica bogate proteinima i količine proteina u 100g namirnice. Dati podaci se nalaze u <a href="https://fdc.nal.usda.gov/">javnoj bazi</a> američkog departmana za agrikulturu.</p>
<h2 id="namirnice-bogate-proteinima">Namirnice bogate proteinima</h2>
<p>Uop&scaron;teno govoreći, hrana bogata proteinima dolazi iz životinjskih izvora poput mleka, mlečnih proizvoda, jaja, ribe i mesa ili biljnih izvora popur semenki i ko&scaron;tunjavog voća.</p>
<RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<p><strong>Biljke</strong></p>
<p>Tokom razmatranja proteinskog sadržaja namirnica biljnog porekla, osim direktne količine proteina bitnu ulogu igra udeo proteina u ukupnom kalorijskom sadržaju. Ukoliko trenirate i želite da smanjite masti u telu a povećate procenat mi&scaron;ića, morate unositi dovoljno proteina a manje kalorija. Prvo će biti predstavljene biljke sa visokim sadržajem proteina nezavisno od količine kalorija, a nakon toga one koje sadrže malo kalorija od čega velika količina dolazi iz protena.</p>
<p><strong>Pasulj</strong> ima veći procenat proteina u odnosu na druge biljke. U zavisnosti od tipa pasulja, uzgoja i drugih faktora 100g svežeg pasulja sadrži izmeću 21 i 25 grama proteina. Naravno, pasulj se konzumira kuvan i tokom kuvanja upija dosta vode. 100g kuvanog pasulja sadrži 9 do 14 grama proteina.</p>
<p><strong>Sočivo</strong> je slično kao pasulj i kuvano sadrži oko 9 grama proteina. Dodatno, sadrži veće količine gvožđa, magnezijuma i vitamina B6.</p>
<p><strong>Leblebije </strong>su poznata zdrava namirnica izuzetno bogata vlaknima, gvožđem, magnezijumom i drugim vitaminima i mineralima. Sadrži i veliku količinu proteina, oko 19 grama na 100 grama leblebija.</p>
<p><strong>Kikiriki</strong> je energetski veoma bogata hrana i 100 grama sadrži vi&scaron;e od 550 kalorija. 26 grama je zaista velika količina proteina, ipak, ukoliko želite da kontroli&scaron;ete svoju težinu, morate biti umereni pri unosu kikirikija po&scaron;to 100 grama sadrži skoro 50 grama masti.</p>
<p><strong>Pistaći</strong> su energetski veoma bogati, poput kikirikija, sadrže blizu 550 kalorija u 100 grama. Imaju ne&scaron;to manje proteina, oko 20 grama, &scaron;to je i dalje veoma velika količina. Pored proteina u njima se nalazi dosta vlakana i velike količine različitih vitamina i minerala.</p>
<p><strong>Bademi</strong> su jo&scaron; jedna namirnica slična kikirikiju. Sirovi bademi sadrže blizu 580 kalorija u 100 grama, ali i veliku količinu proteina, oko 21 gram. Kao i kikiriki i pistaći, bogati su mastima, vlaknima, vitaminima i mineralima.</p>
<p><strong>Ovas</strong> je žitarica sa najvećim procentom proteina. 100 grama suvog ovsa sadrži između 11 i 17 grama proteina. Naravno, kao i sve žitarice, sadrži veliku količinu ugljenih hidrata-oko 66 grama, ali je mali deo u brzo svarljivim, lo&scaron;im, &scaron;ećerima. Zbog velike količine ugljenih hidrata, ne čudi velika količina energije koja za 100 grama ovsa iznosi oko 400 kalorija.</p>
<p><strong>Belo ili bundevino seme</strong> veoma je bogato proteinima i kalorijama. Sadrži 19 grama proteina u 100 grama, ali i 19 grama različitih masti. Veoma je zdravo ukoliko se konzumira u razumnim količinama.</p>
<SquareImage src={FifthImage} alt="Description">
<p><strong>Brokoli</strong> ne sadrži puno proteina, ali je namirnica sa veoma malo kalorija, oko 34 kalorije u 100 grama, od kojih trećina dolazi iz proteina. Brokoli sadrži veliki broj vitamina i minerala i oko 3 grama proteina.</p>
<p><strong>Karfiol</strong> ima jo&scaron; manje kalorija od brokolija, oko 25 kalorija u 100 grama. Gotovo da nema masti, a sadrži samo 2 grama &scaron;ećera i oko 2 grama proteina. Nemoguće je zadovoljiti potrebe za proteinima konzumacijom karfiola, ali u odnosu na količinu kalorija, količina proteina nije zanemarljiva.</p>
</SquareImage>
<p><strong>Mleko, mlečni proizvodi i jaja</strong></p>
<p><strong>Jaja</strong> su poznat izvor dobrih proteina. Jedno veće jaje te&scaron;ko je oko 50 grama i sadrži oko 6 grama proteina. Pored velike količine, proteini jaja imaju veoma dobar kvalitet, odnosno dobar amino kiselinski sastav, o čemu će biti vi&scaron;e reči u daljem tekstu.</p>
<p><strong>Mleko</strong> (kravlje) sadrži malo proteina na 100 grama, oko 3,5 grama. Medjutim, sadrži po malo od svih drugih nutrijenata neophodnih za dobro zdravlje. Tečnost je, tako da je u velikom procentu voda, pa je lako za konzumaciju i sadrži malo kalorija.</p>
<p><strong>Sir</strong> je mlečni proizvod koji može biti napravljen na različite načine, od različitog mleka i mogu mu biti dodate razne začinske biljke, povrće i drugo. Uglavnom sadrži veliku količinu proteina, između 10 i 30 grama. Količine proteina i kalorija nekih poznatih su:</p>
<SquareImageLeft src={SixthImage} alt="Description">
<ul>
<li>Čedar sir &ndash; 25 grama proteina i oko 400 kalorija</li>
<li>Feta sir &ndash; 14 grama proteina i oko 250 kalorija</li>
<li>Parmezan &ndash; 38 grama proteina i 430 kalorija</li>
<li>Edam sir &ndash; 25 grama proteina i 350 kalorija</li>
<li>Gauda sir &ndash; 25 gram proteina i oko 350 kalorija</li>
<li>Mladi sir &ndash; oko 15 grama proteina</li>
<li>Tvrdi sir &ndash; oko 25 grama proteina i 350 kalorija</li>
</ul>
</SquareImageLeft>
<p><strong>Sirni namaz</strong> se danas može naći u različitim oblicima i sa različitim ukusima. Uglavnom sadrži manje masti, tako da mu je energetska vrednost oko 100 kalorija na 100 grama. Ima oko 11 grama proteina.</p>
<p><strong>Jogurt</strong> je namirnica specifična za na&scaron;e krajeve koja sadrži veći ili manji procenat masti, &scaron;to drastično utiče na energetsku vrednost. Uglavnom jogurt sadrži između 40 i 100 kalorija u 100 grama i vi&scaron;e od 3 grama proteina.</p>
<p><strong>Grčki jogurt</strong> sadrži oko 10 grama proteina i manje od 60 kalorija na 100 grama, pa ima bolji odnos kalorija i proteina od drugih mlečnih proizvoda.</p>
<p><strong>Meso i riba</strong></p>
<p><strong>Meso</strong> predstavlja glavni izvor proteina u ishrani ljudi &scaron;irom sveta. U narednom delu biće predstavljeno meso koje je najče&scaron;će kori&scaron;ćeno u na&scaron;oj kuhinji. Podaci se odnose na meso kojem su odstranjeni vidno masni delovi (bela mast), &scaron;to je i savet svetske zdravstvene organizacije.</p>
<SquareImageLeft src={SeventhImage} alt="Description">
<ul>
<li>Piletina &ndash; Pileće grudi (belo meso) sadrže oko 30 grama proteina i 165 kalorija u 100 grama. Veoma su popularna među bodibilderima i sportistima po&scaron;to oko 80% kalorija dolazi iz proteina.</li>
<li>Piletina &ndash; Pileći bataci sadrže oko 26 grama proteina i 210 kalorija u 100 grama mesa.</li>
<li>Piletina &ndash; Pileća krilca sadrže oko 30,5 grama proteina i 200 kalorija u 100 grama mesa.</li>
<li>Svinjetina ima ne&scaron;to manje proteina, 25 grama, ali i puno masti, oko 20 grama. Iz ovog razloga je kalorična i sadrži oko 300 kalorija u 100 grama.</li>
<li>Junetina ima sličan proteinski sadržaj kao i svinjetina i sadrži oko 26 grama proteina, ali ima manje masti pa joj je kalorijska vrednost niža i iznosi oko 220 kalorija.</li>
<li>Ćuretina ima sličan sastav kao i piletina. Sadrži manje proteina u belom mesu, a vi&scaron;e u batacima u odnosu na piletinu, ali su razlike male i zanemarljive.</li>
</ul>
</SquareImageLeft>
<p><strong>Riba</strong> je veoma bitna namirnica koja se, na žalost, ne konzumira dovoljno na na&scaron;im prostorima. Značaj ribe u ishrani uglavnom je vezan za omega 3 masne kiseline, neophodne za normalno funkcionisanje mozga, srca i ostatka tela. Dodatno, riba sadrži značajne količine vitamina D i proteina. Podaci o proteinskom sastavu različitih vrsta riba, koje su po mojem iskustvu popularne na na&scaron;im prostorima, su:</p>
<SquareImageLeft src={EightImage} alt="Description">
<ul>
<li>Som ima odlična nutritivna svojstva. Sadrži dobar odnos omega 3 i omega 6 masnih kiselina, kao i veliku količinu različitih minerala. Kalorijska vrednost je umerena, ali dosta varira u zavisnosti od starosti ribe, kao i komada i načina pripreme. 100 grama pripremljenog soma sadrži vi&scaron;e od 18 grama proteina.</li>
<li>Oslić ima nisku kalorijsku vrednost, manje od 100 kalorija na 100 grama i dosta proteina, oko 18 grama.</li>
<li>Pastrmka u zavisnosti od uzgoja i načina pripreme sadrži vi&scaron;e od 20 grama proteina, ali i veće količine vitamina B12, selena i fosfora.</li>
<li>&Scaron;aran je veoma bogat proteinima, i pripremljen sadrži 23 grama proteina. Dodatno, u njemu se nalazi dosta fosfora i cinka.</li>
<li>Sku&scaron;a spada u kaloričnije ribe i 100 grama ima oko 250 kalorija. Dodatno, ima veliki procenat proteina, oko 24 grama.</li>
<li>Girice su izuzetno kalorična hrana, posebno ako se posmatra način pripreme, koji je uglavnom prženje u ulju. Imaju značajnu količinu proteina, oko 19 grama, ali im je kalorijska vrednost preko 350 kalorija u 100 grama.</li>
</ul>
</SquareImageLeft>
<h2 id="proteini-kvalitet-proteina">Kvalitet proteina</h2>
<p>Pored zadovoljavajućih količina, neophodan je unos proteina dobrog sastava. Kao &scaron;to je već spomenuto, proteini se sastoje od različitih amino kiselina koje na&scaron;e telo koristi za izgradnju svojih proteina i raznih drughi supstanci različite funkcije.</p>
<p>Esencijalne amino kiseline je neophodno uneti hranom, a one dalje bivaju ugrađene u mi&scaron;iće i organe. Procenat esencijalnih amino kiselina u proteinima određuje takozvani kvalitet proteina, a proteini koji imaju takav sastav da se njihovim varenjem zadovolje potrebe tela nazivaju se kompletni proteini.</p>
<p>Kompletni proteini mogu se naći u jajima, nekim ribama (bakalar), mleku i mlečnim proizvodima, junetini, piletini, kinoi, semenu konoplje i heljdi.</p>
<p>Zaključak <a href="https://pubmed.ncbi.nlm.nih.gov/26224750/">velikog istraživanja</a>, koje se bavilo upoređivanjem konzumacije proteina biljnog i životinjskog sastava, zaključeno je da biljni proteini, uop&scaron;teno, pokazuju lo&scaron;ije rezultate od životinjskih. Pretpostavlja se da je razlog ovome lo&scaron;ije varenje i apsorbcija u crevima.</p>
<Conclusion>Proteini se razlikuju po sastavu o čemu se mora voditi računa. Najbolji proteini se nalaze u hrani životinjskog porekla poput jaja, mleka i mesa.</Conclusion>
<h2 id="proteini-visoko-proteinska-ishrana">Visoko proteinska ishrana</h2>
<p>U pro&scaron;losti su postojale brojne predrasude prema visoko proteinskoj ishrani i smatralo se da negativno utiče na funkciju oka, zdravlje kostiju i da može izazvati brojne druge zdravstvene probleme. Međutim, danas imamo jake dokaze da umereno visok unos proteina, kod zdravih individua, ima minimalne rizike.</p>
<p>U ne&scaron;to <a href="https://www.ahajournals.org/doi/full/10.1161/hc4001.096152">starijem radu</a> objavljenom u saradnji sa američkom asocijacijom za zdravlje srca, rečeno je da većina amerikanaca unosi vi&scaron;e proteina nego &scaron;to je to njihovom telu potrebno. Ovi dodatni proteini predstavljaju opterećenje za bubrege i jetru. Dodatni problem može predstavljati povećan unos holesterola i saturisanih masti, kojima je visoko proteinska ishrana bogata.</p>
<p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4424780/">Rad iz 2015</a>, ukazuje da ishrana bogata proteinima &scaron;tetna po ljude sa problemima u radu bubrega, ali da ne utiče lo&scaron;e na bubrege zdravih pojedinaca.</p>
<p><a href="https://jissn.biomedcentral.com/articles/10.1186/1550-2783-11-19">Istraživanje</a> obavljeno na 30 aktivnih, zdravih ispitanika, pratilo je uticaj unosa ekstremnih količina proteina tokom 8 nedelja. Grupa ispitanika unosila je 4.4 grama proteina po kilogramu telesne težine dnevno, dok je druga grupa imala balansiranu ishranu. Zaključeno je da ekstreman unos proteina nije uticao na obim treninga, težinu ispitanika, procenat masti, ni mi&scaron;ićnu masu. Dakle, unos vi&scaron;e od 4 puta preporučene količine proteina dnevno nije imao uticaja na funkcionisanje tela.</p>
<Conclusion>Visoko proteinska ishrana ima minimalne rizike po zdravlje, ali unos veće od potrebne količine proteina je besmislen.</Conclusion>
<h2 id="proteini-proteinski-suplementi">Proteinski suplementi</h2>
<p>Suplementi su danas postali multimilijarderska industrija. Samo u Evropskoj uniji vrednost <a href=" https://ec.europa.eu/food/sites/food/files/safety/docs/fs_labelling-nutrition_special_study.pdf">sportske nutricije 2014</a>. godine pre&scaron;la je 3 milijarde evra. Proteinski suplementi zauzimaju veoma značajno mesto i veoma su prodavani.</p>
<SquareImage src={NinethImage} alt="Description">
<p>U dosada&scaron;njem tekstu obja&scaron;njeno je koliko Vam proteina treba i iz koje hrane ih možete dobiti. Proteini dobijeni hranom bolji su od proteinskih suplemenata jer pored proteina sadrže i sve druge supstance neophodne za život, ali je pri intenzivnom treningu ponekad nemoguće zadovoljiti proteinske potrebe isključivo hranom.</p>
<p>Zlatni standard proteinskih suplemenata je Whey protein ili protein surutke. Ovaj protein je često pome&scaron;an sa &scaron;ećerima kako bi se postigla bolja absorpcija. Protein surutke je kompletan protein, jeftin je i može se lako naći. Merica koja dolazi u pakovanju je 20 do 50 grama, ali ukoliko ga koristite, odredite svoje potrebe i ne oslanjajte se na preporuku proizvođača.</p>
</SquareImage>
<p>Ukoliko ste vegeterijanac, vegan ili iz nekog drugog razloga ne možete da koristite protein surutke, zamena je protein konoplje ili gra&scaron;ka. Ovo su takođe kompletni proteini ali su značajno skuplji i manje dostupni na na&scaron;em trži&scaron;tu.&nbsp;</p>
<Conclusion>Iako proteini iz hrane treba da budu primarni izvor, suplementi poput whey proteina mogu biti korisni za sportiste sa visokim proteinskim potrebama, dok vegetarijanci i vegani mogu koristiti proteine konoplje ili graška kao alternativu.</Conclusion>
<h2 id="proteini-zakljucak">Zaključak</h2>
<p>Dobar i dostupan proteinski suplement je Whey protein (protein surutke), a ako ne možete koristiti njega onda protein konoplje ili gra&scaron;ka.</p>
<p>Druge interesantne članke možete naći na mom <a href="/science/">BLOG-u</a></p>
        </div>
    )
}
export default BlogPost7