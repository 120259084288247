import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';
import FirstImage from '../blog_images/10_zabluda/img1.jpg'
import SecondImage from '../blog_images/10_zabluda/img2.jpg'
import ThirdImage from '../blog_images/10_zabluda/img3.jpg'
import FourthImage from '../blog_images/10_zabluda/img4.jpg'
import FifthImage from '../blog_images/10_zabluda/img5.jpg'
import SixthImage from '../blog_images/10_zabluda/img6.jpg'
import SeventhImage from '../blog_images/10_zabluda/img7.jpg'
import EightImage from '../blog_images/10_zabluda/img8.jpg'

const BlogPost6 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere'}}>
<p>Usled stalnih novih saznanja i istraživanja, ali i čestih zastarelih verovanja veliki je broj zabluda o ishrani koje danas možemo čuti iz različitih izvora. Neke od ovih pogre&scaron;nih informacija bila su stavovi nauke u pro&scaron;losti, druga su ostaci tradicije, a treća namerne dezinformacije sa ciljem prodaje proizvoda i preparata. Bilo kako bilo, u daljem tekstu će biti navedeno 10 od ovih zabluda sa obja&scaron;njenjima.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Pre početka, želeo bih da kažem da ozbiljnija neslaganja poput uticaja unosa holesterola, kuhinjske soli, prerađenih &scaron;ećera, i dr. ovde neće biti spomenuta, kako zaslužuju čitave tekstove koji se bave samo njima. 10 pogre&scaron;nih mi&scaron;ljenja kojima se ovaj tekst bavi odabrana su prema mome ličnom osećaju za njihovu učestalost i zanimljivost.</p>
<ol>
<li>Vitamin C sprečava kijavicu i grip</li>
<li>Manje ugljenih hidrata, zdravija ishrana</li>
<li>Med i braon &scaron;ećer su zdraviji od belog</li>
<li>Zaleđeno i konzervirano voće i povrće gore je od svežeg</li>
<li>Prirodno uvek znači zdravo</li>
<li>Crveno vino je jedini dobar alkohol</li>
<li>Ukoliko vežba&scaron; može&scaron; da jede&scaron; &scaron;ta i koliko hoće&scaron;</li>
<li>Treba izbegavati jaja zbog velike količine holesterola</li>
<li>Fla&scaron;irana voda je bolja od one sa česme</li>
<li>Nikada ne sme&scaron; da jede&scaron; brzu hranu</li>
<li>Vitamin C sprečava kijavicu</li>
</ol>
<p>Ova tvrdnja spada u zastarelo naučno mi&scaron;ljenje za koje se dokazalo da je pogre&scaron;no. Pre oko 50 godina popularizovana je ideja da vitamin C pomaže u lečenju kijavice. Od tada je urađeno puno istaživanja koja su poku&scaron;avala da ovo i dokažu, na žalost bezuspe&scaron;no. Većina istaživanja ukazala je da vitamin C ima veoma mali do nikakav uticaj na ozbiljnost i trajanje kijavice. Ostaju nam sigurne preporuke, poput dobre hidriranosti, dosta odmora, kapi za nos i puno tolet papira.</p>
<h2 id='10-zabluda-manje-ugljenih-hidrata'>Manje ugljenih hidrata uvek znači zdraviju ishranu</h2>
<p>Prema mom iskustvu ovo je veoma česta zabluda o ishrani, koja postoji iz vi&scaron;e razloga.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Mi&scaron;ljenje da su ugljeni hidrati uvek lo&scaron;i je jednostavno pogre&scaron;no. Dok se mogu složiti da treba izbegavati namirnice sa prekomernim dodatim prostim &scaron;ećerima, ugljeni hidrati su ključni su za noramalan metabolizam i zdravo telo. Ugljeni hidrati iz voća i povrća vekovima su prisutni u ljudskoj ishrani, a pored ugljenih hidrata, u njima se nalazi veliki broj neophodnih vitamina i minerala, tako da ih nikako ne možemo smatrati nezdravom hranom.</p>
<p>Kompanije prodaju različite proizvode sa niskim sadržajem &scaron;ećera koji bivaju zamenjeni ve&scaron;tačkim zaslađivačima u cilju održanja ukusa. Ovime se postiže promocija i prodaja proizvoda za koje potro&scaron;ači smatraju da su zdraviji ili dobri za njih. Tačni efekti ve&scaron;tačkih zaslađivača su kompleksni i često nedovoljno proučeni, a ukoliko želite da se hranite zdravije, poku&scaron;ajte da proredite slatki&scaron;e, sokove i grickalice, umesto da tražite sličan ukus sa manje &scaron;ećera.</p>
</SquareImage>
<p>Ljudi koji se bave sportom, ili su na drugi način fizički aktivni kao osnovni izvor energije koriste ugljene hidrate. Za ove ljude, ne samo da unos ugljenih hidrata nije lo&scaron;, već je i preporučen. Postizanje vrhunskih rezultata u sportu, gotovo je nezamislivo bez dovoljnog unosa namirnica bogatih ugljenih hidratima.</p>
<Conclusion>Ugljeni hidrati su važan deo zdrave ishrane, posebno kada se unose kroz voće i povrće.</Conclusion>
<h2 id='10-zabluda-med-i-braon-secer'>Med i braon &scaron;ećer su zdraviji od belog &scaron;ećera</h2>
<p>Braon i beli &scaron;ećer dobijaju se iz iste biljke, pa su slične slasti i hemijskog sastava. Braon &scaron;ećer sadrži nutritivno beznačajno veće količine nekih minerala, tako da nema nikakvih benefita po zdravlje. Postoje veoma <a href="https://fdc.nal.usda.gov/">male razlike</a> u njihovoj kalorijskoj vrednosti, oko 8%, i najveću razliku predstavljaju ukus i sama boja.</p>
<p>Razlika između meda i belog &scaron;ećera je ne&scaron;to veća. Med je poznat kao zdrava namirnica, ali trebalo bi da znate da je po hemijskom sastavu, pored vode, med dominantno me&scaron;avina glukoze i fruktoze, isto kao i kuhinjski beli &scaron;ećer. Pored prostih &scaron;ećera, med sadrži vitamine i antioksidanse koji mogu imati benefita po zdravlje. Med i dalje treba kotistiti u manjim količinama, posebno ukoliko poku&scaron;avate da smanjite kalorijski unos.</p>
<Conclusion>Braon šećer  nema  značajnih  nutritivnih  prednosti  u odnosu na  beli šećer.</Conclusion>
<h2 id='10-zabluda-zaledjeno-i-konzervirano'>Zaleđeno i konzervirano voće i povrće gore je od svežeg</h2>
<p>Na početku, treba da razumete da voće i povrće nastavlja da &bdquo;živi&rdquo; i nakon branja. Tokom ovog perioda tro&scaron;e se uskladi&scaron;teni nutrijenti na održavanje ćelija živim. Dok sveže namirnice uvek treba da budu prvi izbor, ovo je često nemoguće postići. Veliki broj voća i povrća počinje da gubi svoja svojstva samo nekoliko sati nakon &scaron;to je ubrano, a često nije moguće obaviti ovako brz transport od proizvođača do potro&scaron;ača. Voće i povrće prvo provodi vreme u putu, zatim na policama, nakon toga u na&scaron;im domovima pre nego &scaron;to ga konzumiramo.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Sa druge strane zamrznuto i konzervirano voće i povrće uglavnom biva zapakovano veoma brzo nakon branja. Ovime se prekida dalje gubljenje nutrijenata i u na&scaron;e domove dolazi produkt produkt koji je po sastavu svežiji od onog kupljenog na pijaci. Naravno, neke biljke prirodno dobro prezerviraju nutijente u njima i nije ih neophodno zamrzavati ili konzervirati. Ovde spadaju pasulj, limun, krompiri, &scaron;argarepe i druge krtole.</p>
<p>Ukoliko biram između zaleđene i konzervirane hrane, odabrao bih zaleđenu. U konzerviranoj se često nalaze dodate soli i &scaron;ećeri, a uticajpovi&scaron;enih temperatura tokom transporta može ugroziti nutritivnu vrednost hrane. Zaleđena hrana je uglavnom nema dodatih supstanci i ostaje zaleđena tokom transporta i nakon kupovine.</p>
</SquareImageLeft>
<Conclusion>Iako sveže treba da budu prvi izbor, zamrznuto i konzervirano voće i povrće su odlične alternative, posebno za namirnice koje brzo gube nutrijente.</Conclusion>
<h2 id='10-zabluda-prirodno-znaci-zdravo'>Prirodno uvek znači zdravo</h2>
<p>Ovom mi&scaron;ljenju se može prići sa vi&scaron;e strana. Za početak, iz perspektive većine biljaka sisari, uključujući i ljude predstavljaju &scaron;tetočine. Biljke su evoluirale u smeru za&scaron;tite od nas, tako da ne čudi &scaron;to u sebi sadrže supstance koje su &scaron;kodljive ili čak smrtonosne po nas. Na primer, divlji badem sadrži cijanide koji u malik količinama mogu ubiti čoveka, krompir, gra&scaron;ak i pasulj mogu izazvati te&scaron;ka stanja ukoliko nisu dobro skuvani itd. Tokom istorije, ljudi su naučili kako da pripitome, uzgoje i pripreme biljke tako da ne budu opasne po njih. U ovom smislu, uzgojene bilke uglavnom imaju bolja nutritivna svojstva od njihovih divljih rođaka.</p>
<RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<p>U pogledu medicine, priroda nam je dala neke od najče&scaron;će kori&scaron;ćenih lekova poput aspirina, morfina, pa čak i penicilina. Međutim, prirodni i tradicionalno kori&scaron;ćeni lekovi često su se pokazali kao neefikasni. Ova odluka je naravno u potpunosti va&scaron;a da je napravite, ali savremena medicina je gravni razlog za drastično produženje života ljudi u 20. i 21. veku.</p>
<p>Na kraju, organska i prirodno proizvedena hrana zdravija je od hrane proizvedene uobičajnim tehnikama, kako je kori&scaron;ćeno manje hemikalija. Problem može biti iluzija da kada je ne&scaron;to obeleženo sa organsko ili prirodno odmah znači i zdravo. Ne treba zaboraviti da i ovim namirnicama mogu biti dodati &scaron;ećeri, soli, aditivi i dr. ili mogu sadržati organskih proizvoda u beznačajno malim količinama. Savetujem Vam da uvek pročitate deklaraciju potro&scaron;ača i nakon toga zaključite od zdravosti namirnice.</p>
<Conclusion>Organska i prirodna hrana nisu uvek zdravije, pa je važno čitati deklaracije i donositi informisane odluke.</Conclusion>
<h2 id='10-zabluda-crveno-vino'>Crveno vino je jedini dobar alkohol</h2>
<SquareImage src={FifthImage} alt="Description">
<p>Jo&scaron; jedna zabluda zasnovana na zastarelom naučnom mi&scaron;ljenju je o crvenom vinu. Pre vi&scaron;e od 50 godina naučnici su pratili zdravlje srca ljudi &scaron;irom sveta. Među populacijama ljudi koja je svakodnevno pila vino primetili su manji broj obolelih od kardio-vaskularnih bolesti nego među drugim populacijama. Međutim, kasnije se ispostavilo da je za ovo drastično vi&scaron;e odgovorna njihova ishrana, nego konzumacija crvenog vina. Danas se ljudima koji imaju problema sa srcem preporučuje mediteranska ishrana ili specijalna DASH dijeta napravljena po uzoru na mediteransku.</p>
<p>Umerene količine alkohola imaju neke pozitivne efekte ne zdravlje. Naravno naglasak je na reči umereno, po&scaron;to su posledice prekomerne konzumacije alkohola veoma lo&scaron;e po zdravlje. Kroz vi&scaron;e studija pokazano je da alkohol može doprineti zdravlju srca kroz povećanje nivoa HDL-a (dobrog holesterela) i smanjenje &scaron;anse za nastanak krvnih ugru&scaron;aka. Delotvornost alkohola je upitna i treba reći da doktori ne preporučuju da pijete alkohol u cilju pobolj&scaron;anja zdravlja srca.</p>
</SquareImage>
<p>Ne postoje nikakvi dokazi da pivo, belo vino ili druga alkoholna pića ne postižu iste benefite po zdravlje kao crveno vino. Tako da, odaberite svoje omiljeno piće, pite ga umereno i uživajte.</p>
<Conclusion>Umerena konzumacija alkohola, bez obzira na vrstu, može imati neke pozitivne efekte na zdravlje srca, ali ne treba piti alkohol isključivo u te svrhe.</Conclusion>
<h2 id='10-zabluda-ukoliko-vezbas'>Ukoliko vežba&scaron; može&scaron; da jede&scaron; &scaron;ta i koliko hoće&scaron;</h2>
<SquareImageLeft src={SixthImage} alt="Description">
<p>Dok je trening nedvosmisleno bitan za izgradnju mi&scaron;ića i veoma važan za gubitak neželjenih kilograma, treba voditi računa o tačnom povećanju kalorijskih zahteva. Ljudski organizam ima težnju da energiju tro&scaron;i &scaron;to racionalnije, zbog čega, tokom fizičke aktivnosti ne postoji veliko povećanje potro&scaron;nje. Procenjena potro&scaron;nja za osobu koja ima izuzetno malo ili gotovo da nema fizičku aktivnost je 2000 kalorija za žene i 2400 kalorija za mu&scaron;karce. Treningom se potro&scaron;nja, u zavisnosti od intenziteta, povećava do 600 kalorija na dan. 600 kalorija nije malo, za dosta ljudi ovoliko ima u čitavom obroku. Npr. Pljeskavica od 150 grama u lepinji ima oko 600 kalorija.</p>
<p>Ovo naravno ne važi za profesionalne sportiste, kao ni za ljude koji imaju svakodnevne treninge izuzetnog intenziteta ili trajanja. Za ovu populaciju, neophodno je preciznije, kori&scaron;ćenjem različitih metoda, izračunati potro&scaron;nju. Međutim, ovo je takođe populacija koja veoma pazi na svoj kalorijski unos, odnosno kada i koliko jede. Dakle čak ni ljudi koji imaju daleko veću potro&scaron;nju od prosečnih aktivnih ljudi ne mogu da unose neograničene količine kalorija.</p>
</SquareImageLeft>
<p>Za kraj, želeo bih da podsetim da je pored kalorija neophodno voditi računa o pravilnom unosu vitamina, minerala, vode i proteina. Njihov prepopručeni dnevni unos uvek je prikazan za osobu koja tro&scaron;i 2000 kalorija tako da realne vrednosti treba prilagoditi va&scaron;oj potro&scaron;nji.</p>
<Conclusion>Čak i  uz  redovan trening,  neophodno je  voditi  računa  o  ishrani  i  unosu  kalorija  kako bi  se  postigli  željeni  rezultati  u  mršavljenju.</Conclusion>
<h2  id='10-zabluda-jaja-i-holesterol'>Treba izbegavati jaja zbog velike količine holesterola</h2>
<p>Tokom pro&scaron;log veka primećeno je da ljudi koji imaju problema sa kardio-vaskularnim sistemom imaju povi&scaron;en holesterol u krvi. Iz ovih razloga je u nutritivne preporuke u&scaron;ao savet da se izbegava hrana bogata holesterolom u cilju prevencije ovih bolesti.</p>
<p>Ova pretpostavka pokazala se pogre&scaron;nom iz vi&scaron;e razloga. Zaključeno je da povećanje holesterola u krvi uzrokovano gojazno&scaron;ću i prekomernim unosom zasićenih i trans masti. Sa druge strane, pokazano je da unos holesterola kroz hranu ima malo do nikakvih efekata na nivo holesterola u krvi.</p>
<p>Koko&scaron;ija jaja su izuzetna namirnica. Ne samo da sadrže idealni proteinski profil već i brojne minerale i vitamine, poput vitamina A, D, B2, B5, B9 i B12, selena i gvožđa. Iz ovih razloga nema nikakvog razloga za izbegavanje jaja u ishrani.</p>
<p>Ukoliko imate problem sa povi&scaron;enim holesterolom neke od preporuka su: smanjenje težine, popravljanje ishrane, vežba i prestanak pu&scaron;enja.</p>
<Conclusion>Unos holesterola kroz hranu ima minimalan uticaj na nivo holesterola u krvi, pa nema potrebe izbegavati namirnice bogate holesterolom, poput jaja.</Conclusion>
<h2 id='10-zabluda-flasirana-voda'>Fla&scaron;irana voda je bolja od one sa česme</h2>
<p>U nekim delovima Srbije ovaj izbor ne postoji zbog neispravnosti vode sa česme i ostatak obja&scaron;njenja odnosi se na one krajeve koji imaju pitku vodu (ne Zrenjanin). Ukoliko imate mogućnost izbora moji savet bi uvek bio voda sa česme iz vi&scaron;e razloga.</p>
<p>Problemi fla&scaron;irane vode su cena, transport, ambalaža i za nas najznačajniji mikroplastika. Mikroplastika je najče&scaron;ći zagađivač fla&scaron;irane vode. Najveći deo se oslobađa tokom pravljenja ambala&scaron;e i pakovanja vode, dok se drugi oslobađa tokom stajanja vode na policama i frižiderima. Mi&scaron;ljenje prodavaca da voda ne može da se pokvari, prema mom zapažanju, često vodi ne vođenju računa o temperaturi i drugim uslovima u kojima se fla&scaron;irana voda skladi&scaron;ti. Ovo vodi povećanom oslobađanju mikroplastike, koja ima dokazano negativne posledice po zdravlje poput inflamacije i akomulacije u organima.</p>
<RectangleImage src= {SeventhImage} alt="Description"></RectangleImage>
<p>Sa druge strane voda sa česme je praktično besplatna i ne sadrži mikroplastiku. U zavisnosti od stanja i starosti vodovodnih cevi može biti zagađena te&scaron;kim metalima, ali se ovo relativno lako i jeftino može proveriti. Dodatno, ne stvara se đubre i bočicu možete puniti uvek i svuda.</p>
<p>Ukoliko niste zadovoljni ukusom ili sastavom vode sa česme, savetujem Vam kori&scaron;ćenje preči&scaron;ćivača. Postoji veliki broji preči&scaron;ćivača i nezahvalno je govoriti o tome koji je najbolji. U zavisnosti od cene koju ste voljni da platite, učestalosti menjanja filtera i Va&scaron;ih potreba razlikuje se idealni filter za Vas. Ja posedujem najobičniji filter sa aktivnim ugljenikom koji koristim kada mi nestane arte&scaron;ke vode, koju kupujem u obližnjem bunaru. Ova voda je definitivno najbolji izbor, ne samo da zbog kratkog stajanja u platičnim fla&scaron;ama sadrži minimalne količine mikroplastike, već dodatno, sadrži minerale, pitka je i ne prolazi kroz potencionalno lo&scaron; cevovod.</p>
<Conclusion>Flaširana voda  često  sadrži  mikroplastiku  koja  može  imati  negativne  posledice  po  zdravlje.</Conclusion>
<h2 id='10-zabluda-brza-hrana'>Nikada ne sme&scaron; da jede&scaron; brzu hranu</h2>
<SquareImageLeft src={EightImage} alt="Description">
<p>Ono &scaron;to ponavljam svim svojim klijentima je da je bitno kako se hranimo i pona&scaron;amo većinu vremena, a ne u jednom danu ili nedelji. Ovo se odnosi i na brzu hranu. Svi uživamo da pojedemo hranu sa obližnjeg kioska ili da ne&scaron;to naručimo. Brza hrana je veoma jeftina, ukusna, ne moramo da je pripremamo i dostupna nam je uvek. Međutim, danas su svi svesni njene nezdravosti zbog velike količine kalorija, masti i dodatih &scaron;ećera.</p>
<p>Brza hrana je osmi&scaron;ljena i napravljena tako da izaziva lučenje dopamina u mozgu. Dopamin je neurotransmiter koji ima ključnu ulogu u osećaju zadovoljstva, tako da ukoliku uživate u brzoj hrani pripadate većinskom delu čovečanstva i to nikako nije lo&scaron;e.</p>
<p>Problem koji se javlja kod ljudi na različitim režimima ishrane je &scaron;to sebe krve kao nedovoljno jake ili uporne da se svaki dan u mesecu suprodstave porivu ka hrani koju vole. Ovo vodi prekidanju dijete ili zdrave ishrane pod utiskom da su praveći jednu &bdquo;gre&scaron;ku&rdquo; poni&scaron;tili svoj dosada&scaron;nji napredak, &scaron;to apsolutno nije istina. Bitna je većina dana, ne svaki dan.</p>
</SquareImageLeft>
<p>Moji savet je da umesto svakodnevnog razmi&scaron;ljanja i suprodstavljanja želji ka ovoj definitivno ukusnoj hrani, isplanirate dan kada ćete je jesti kao jedan od obroka. Naravno, ovo ne treba da se radi svaki dan, ali jedan do dva nespojena dana nedeljno većini ljudi neće predstavljati problem po zdravlje ili u poku&scaron;aju da smr&scaron;aju.</p>
<Conclusion>Ne  dozvolite da  vas  povremeno  konzumiranje  brze hrane  obeshrabri  i  navede  da  odustanete  od  zdravih  navika  u  ishrani,  jer  je  bitno  kako se  hranite  većinu  vremena.</Conclusion>
<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost6