import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardContent, Typography, Box } from '@mui/material';
import reviews from './reviews.json';

const TestimonialsCarousel = () => {
    return (
        <Carousel
            showArrows={true}
            autoPlay
            infiniteLoop
            interval={5000}
            showThumbs={false}
            showStatus={false}
            emulateTouch
        >
            {reviews.map((review) => (
                <Box 
                    key={review.id} 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        padding: '10px',
                        width: '100%',
                    }}
                >
                    <Card 
                        sx={{ 
                            width:'100%',
                            maxWidth: 600,
                            backgroundColor: '#1b3942', 
                            color: 'white',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            borderRadius:'25px'
                            
                        }}
                    >
                        <CardContent>
                            <Typography variant="body2" component="p" style={{ textAlign: 'center', fontSize: '20px' }}>
                                "{review.text}"
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary" style={{ textAlign: 'center', color: 'white', marginTop: '10px', fontStyle:'italic' }}>
                                - {review.author}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            ))}
        </Carousel>
    );
};

export default TestimonialsCarousel;
