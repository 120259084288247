import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';
import FirstImage from '../blog_images/alkohol_i_zdravlje/img1.jpg'
import SecondImage from '../blog_images/alkohol_i_zdravlje/img2.jpg'
import ThirdImage from '../blog_images/alkohol_i_zdravlje/img3.jpg'
import FourthImage from '../blog_images/alkohol_i_zdravlje/img4.jpg'
import FifthImage from '../blog_images/alkohol_i_zdravlje/img5.jpg'


const BlogPost8 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>
<p>Ispijanje alkohola ima duboke istorijske i kulturološke korene. Postoje <a href="https://mediatum.ub.tum.de/doc/1315510/1315510.pdf">arheološki dokazi</a> o proizvodnji i konzumaciji alkohola koji datiraju pre više od 7.000 godina. Uticaj alkohola na čoveka se istražuje već dugi niz godina i do danas nemamo sve zaključke.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Često ispijanje alkohola je normalna stvar u našoj zemlji i nije neobično da ga ljudi konzumiraju od ranog adolescentskog perioda. Sve proslave, od rođenja deteta do sahrane, uključuju alkohol. Moglo bi se reći da je rakija deo našeg nacionalnog identiteta. Dok je većina ljudi upoznata sa opasnostima preteranog unosa alkohola, to šta je preterana količina unetog alkohola stvar je lične interpretacije. Ovo je jedan od razloga zbog kog puno ljudi pije velike količine alkohola sa stavom da je to normalno i da piju manje od svog okruženja. Samim tim neće nas iznenaditi činjenica da se stanovnici Srbije nalaze među <a href="https://www.rts.rs/lat/vesti/drustvo/4776303/srbija-alkoholizam-bolest-zavisnost-lecenje.html">prvima na Balkanu</a> po potrošnji alkohola.</p>
<p>Kako moj pristup navikama podrazumeva balansiranje između uživanja u životu i očuvanja zdravlja, svrha ovog članka nije da bilo koga ubedi da prestane da pije ili da se odrekne bilo čega. Želim da vam pružim pouzdane informacije o alkoholu, opišem stavove stručnjaka i svoje mišljenje na osnovu toga, kako biste mogli da kao dobro informisani donesete svesne odluke o svom načinu življenja.</p>

<h2>Prednosti unosa alkohola</h2>
<p>Kako zloupotreba alkohola ima ozbiljne zdravstvene i socijalne posledice, većina istraživača se sa pravom usredsređuje na negativne i štetne efekte preteranog unosa alkohola. Opšte prihvaćeni stav je da treba izbegavati konzumaciju alkohola, a ako se pije, da se pije što manje i što ređe. Međutim, pored poznatih negativnih efekata i preporuka različitih organizacija alkohol i dalje ispija više od <a href="https://www.who.int/news/item/21-09-2018-harmful-use-of-alcohol-kills-more-than-3-million-people-each-year--most-of-them-men">polovina stanovništva</a> razvijenog dela sveta sa tendencijom rasta brojki. Pitanje je:</p>
<p><em>Zašto</em>?</p>
<p>Najčešće pretpostavke za razlog ispijanja alkohola su hedonističke (zadovoljstvo i nagrada), anksiolitičke (smanjenje stresa i anksioznosti) i zavisničke (jednom kada kreneš, teško je prestati). Mišljenja sam da pored ovih postoje druge realne ili prividne prednosti unosa alkohola, pre svega na polju socijalnih interakcija, samopouzdanja i otvorenosti za kontakt sa novim ljudima.</p>
<p>Dok pričamo o nekim prednostima unosa alkohola, želeo bih da istaknem da sva istraživanja ukazuju da su mane unosa alkohola veće i značajnije od pozitivnih efekata.</p>
<Conclusion>Razlozi za konzumaciju alkohola obično uključuju uživanje, smanjenje stresa i zavisnost, ali i poboljšanje socijalne interakcije i samopouzdanja.</Conclusion>
<h3>Socijalne prednosti</h3>
<p>Alkohol, poput drugih pobuđujućih supstanci kao što su kokain i amfetamini, <a href="https://www.science.org/doi/10.1126/scitranslmed.3002902">dovodi do</a> povećanog lučenja endorfina. Ovi mali peptidi koji se prirodno luče u mozgu <a href="https://pubmed.ncbi.nlm.nih.gov/27238727/">imaju</a> pozitivan uticaj na socijalno razumevanje, komunikaciju i održavanje dugoročnih odnosa i prijateljstva kod svih primata uključujući i ljude.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Druge radnje koje stimulišu endorfin uključuju smejanje, pevanje, igru i pričanje priča, koje su često praćene i pojačane konzumacijom alkohola. Iz ovih razloga može se zaključiti da umerena konzumacija alkohola može imati pozitivne efekte na socijalno ponašanje i život čoveka.</p>
<p>Iz mog iskustva u radu sa ljudima, situacija je slična. Praktično svi ljudi koji redovno piju sa kojima sam razgovarao, to rade iz socijalnih razloga. Da li je uzrok ovome poboljšanje socijalnih odnosa koje alkohol donosi ili pritisak koji okruženje na njih vrši, ostaje upitno.</p>
<p>Veoma <a href="https://link.springer.com/article/10.1007/s40750-016-0058-4">interesantna studija</a> objavljena 2017. ispitivala je povezanost socijalnih navika sa konzumacijom alkohola. Tražili su mešovitoj grupi Britanaca da odgovori na nekoliko pitanja uključujući: koliko često izlaze u pab, da li se često druže i piju i da li imaju „svoj” pab. Nakon toga, tražili su im da ocenom od 1 do 7 procene osećanja poput svoje osećaja sreće, anksioznosti, zadovoljstva, odnosa sa ljudima i slično.</p>
</SquareImage>

<p>Podaci su pokazali da se ljudi koji umereno piju osećaju srećnije, zadovoljnije, da imaju više poverenja u druge i da se osećaju bolje povezano sa svojom lokalnom zajednicom. Dodatno, oni koji su imali „svoje” mesto i redovno ga posećivali imali su još pozitivniji osećaj prema ovim stvarima.</p>
<p>Iako su rezultati istraživanja interesantni, način izvođenja ove studije je upitan jer je izvedena onlajn na relativno malom uzorku od 2.254 ljudi. Dodatno, ostaje uvek aktuelno pitanje „kokoške ili jajeta” tj. da li su ljudi koji piju više socijalno aktivni zato što piju ili piju zato što su više socijalno aktivni. Očekivano je da su introverti ređe van kuće, pa samim tim i ređe konzumiraju alkohol, kao i suprotno.</p>
<p>Još jedna <a href="https://www.psychologicalscience.org/news/releases/moderate-doses-of-alcohol-increase-social-bonding-in-groups.html">grupa naučnika</a> bavila se sličnim pitanjem. Pravili su raznolike manje grupe ljudi koristeći 720 volontera i zaključili da alkohol stimuliše povezivanje unutar grupe. Dodatno, nakon konzumacije male količine alkohola, ljudi više pričaju jedni sa drugima, ređe ispoljavaju negativne emocije i češće se iskreno smeju.</p>
<Conclusion>Umerena konzumacija alkohola može pozitivno uticati na socijalno ponašanje, pojačavajući osećaj povezanosti i zadovoljstva unutar grupe, ali je pitanje uzroka i posledice između socijalne aktivnosti i konzumacije alkohola i dalje otvoreno.</Conclusion>
<h3>Zdravstvene prednosti</h3>
<p>U prošlosti su postojale razne teorije o potencijalnim pozitivnim efektima umerenih količina alkohola na zdravlje čoveka. Pronašao sam veći broj istraživanja koja ukazuju na pozitivne efekte alkohola na zdravlje srca, ali i veći broj boljih istraživanja koja to poriču. Moj zaključak je da su zdravstveni benefiti relativno mali i vrlo specifični, pa ako odlučite da pijete, setite se da to radite radi uživanja i druženja, a ne radi zdravlja.</p>
<p>Postoje <a href="https://pubmed.ncbi.nlm.nih.gov/28818200/">dokazi</a> da konzumacija male ili umerene količine alkohola vodi smanjenju šanse od smrti uzrokovane raznim oboljenjima, a posebno raznim vrstama kancera. Takođe, postoje <a href="http://ncbi.nlm.nih.gov/pmc/articles/PMC9115901/">jaki dokazi</a> kada je reč o benefitima malih ili umerenih količina alkohola na smanjenje rizika od raka bubrega, raka debelog creva, demencije, kardiovaskularnih oboljenja kod ljudi sa hipertenzijom, kao i od kardiomiopatije.</p>
<Conclusion>Iako neka istraživanja ukazuju na male zdravstvene koristi umerenog unosa alkohola, posebno za određene bolesti, ti benefiti su ograničeni i ne bi trebalo da budu primarni razlog za konzumaciju alkohola.</Conclusion>
<h2>Mane unosa alkohola</h2>
<p>Prema podacima za 2019. <a href="https://www.who.int/news-room/fact-sheets/detail/alcohol">smatra se</a> da je alkohol doprineo smrti 2,6 miliona ljudi na svetu. Među njima najugroženiji su uzrasta 20 - 39 godina. Alkohol je odgovoran za preko 200 različitih bolesti, povreda i zdravstvenih stanja i <a href="https://www.thelancet.com/journals/lanonc/article/PIIS1470-2045(09)70326-2/abstract?code%20=lancet-site">klasifikovan je</a> kao supstanca koja izaziva zavisnost, koja je toksična i ima psihoaktivna svojstva.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<h3>Zdravstveni problemi</h3>
<p>Najveću zabrinutost po zdravlje izaziva visoka kancerogenost alkohola, koji je <a href="https://movendi.ngo/science-digest/world-health-organization-no-level-of-alcohol-use-is-safe-for-our-health/">svrstan</a> u najvišu <em>grupu 1 kancerogenih supstanci</em> zajedno sa azbestom, radijacijom i duvanom. Alkohol <a href="https://www.who.int/europe/news/item/04-01-2023-no-level-of-alcohol-consumption-is-safe-for-our-health">povećava</a> rizik od barem sedam vrsta kancera, uključujući rak bešike i rak dojke. Svetska zdravstvena organizacija <a href="https://www.who.int/europe/news/item/04-01-2023-no-level-of-alcohol-consumption-is-safe-for-our-health">ukazuje</a> da alkohol pokazuje kancerogena svojstva nezavisno od količine i učestalosti konzumacije. <a href="https://ascopubs.org/doi/full/10.1200/JCO.2017.76.1155">Znamo</a> da su negativna svojstva direktno povezana sa količinom i učestalošću i da su nezavisna od kvaliteta i tipa pića, jer su sam alkohol i supstance koje nastaju pri njegovom varenju toksične.</p>
<p>Postoje <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9115901/">jaki dokazi</a> da alkohol povećava rizik od raka jednjaka, dojke i kože čak i pri malom unosu (manje od jednog pića dnevno). Pri srednjem i visokom unosu, dodatno povećava rizik od raka rektuma i raka želuca.</p>
</SquareImageLeft>
<p>Za razliku od duvanske industrije, alkoholna industrija nije u obavezi da svoje potrošače obaveštava o kancerogenosti proizvoda. Da alkohol povećava rizik od kancera naučni svet zna već decenijama, ali opšta javnost <a href="https://www.who.int/europe/news/item/04-01-2023-no-level-of-alcohol-consumption-is-safe-for-our-health">nije upoznata</a> sa ovom činjenicom i to je svakako nešto na čemu treba raditi.</p>
<p>Drugi zdravstveni problemi kojima veliki unos alkohola doprinosi su zaista brojni i <a href="https://www.cdc.gov/alcohol/about-alcohol-use/index.html">uključuju</a>: visok krvni pritisak, bolesti srca, šlog, bolesti jetre, digestivne probleme i slabljenje imunog sistema. Mogao bih nadugačko da pišem o tome kako i koje probleme velike količine alkohola izazivaju, ali mislim da je dovoljno reći da veoma loše utiče na celokupno telo, kao i da vodi nizu drugih problema.</p>
<Conclusion>Alkohol, bez obzira na količinu i učestalost unosa, povećava rizik od više vrsta raka i brojnih drugih ozbiljnih zdravstvenih problema, što ga čini značajnim faktorom rizika za celokupno zdravlje.</Conclusion>
<h3>Psihički problemi</h3>
<SquareImage src={FourthImage} alt="Description">
<p>Alkohol je depresant, što znači da vodi osećaju opuštenosti, smanjene anksioznosti i povećanju samopouzdanja. Međutim, nakon što efekti alkohola prođu za njima ostaje promenjena hemija mozga i dolazi do povećanja besa, depresije i anksioznosti. Dugoročni efekti zloupotrebe alkohola su problemi sa pamćenjem, smanjenje kognitivnih funkcija, povećan rizik od samoubistva i povećana šansa za razvojem alkoholizma.</p>
<p>Depresija je <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6799954/">najčešći</a> psihički problem koji se javlja kod ljudi koji zloupotrebljavaju alkohol. Dok postoji depresija koja je najpre prouzrokovana zloupotrebom alkohola, pokazalo se da ljudi koji su već u depresiji češće kreću da zloupotrebljavaju alkohol, tako da su ova dva problema vrlo blisko povezana. Ono što definitivno znamo jeste to da alkohol ne doprinosi rešavanju problema sa depresijom, već ga, nasuprot, pogoršava.</p>
<p>Više od 720 hiljada ljudi na svetu godišnje <a href="https://www.who.int/news-room/fact-sheets/detail/suicide">počini</a> samoubistvo, pri čemu je populacija između 15 i 29 godina najviše pogođena. Dok su razlozi za samoubistvo različiti, postoji jaka veza samoubistva sa mentalnim poremećajima, prvenstveno depresijom i alkoholizmom. <a href="https://mentalhealthcommission.ca/resource/alcohol-use-and-suicide-fact-sheet/">Razlog</a> zašto alkohol toliko značajno povećava rizik je što se pod uticajem alkohola smanjuje osećaj straha i kontrole, donose loše odluke i procene i povećava impulsivnost i agresija.</p>
</SquareImage>
<Conclusion>Alkohol može privremeno smanjiti anksioznost, ali dugoročno povećava rizik od depresije, kognitivnog pada i samoubistva, dodatno pogoršavajući mentalno zdravlje i povezujući se sa impulsivnim ponašanjem i agresijom.</Conclusion>
<h3>Socijalni problemi</h3>
<p>Pored zdravstvenih i psihičkih problema koje zloupotreba alkohola izaziva, postoji čitav niz socijalnih i individualnih problema u odnosu sa ljudima koji se ne mogu zanemariti u pokušaju predstavljanja celokupne slike o alkoholu. Dugoročno alkohol <a href="https://www.nhs.uk/conditions/alcohol-misuse/#:~:text=As%20well%20as%20causing%20serious,as%20dependent%20drinking%20(alcoholism).">doprinosi</a> nezaposlenosti, razvodima, porodičnom nasilju i beskućništvu.</p>
<p><a href="https://journals.sagepub.com/doi/10.1177/0253717620956466">Mala studija</a> koja je ispitivala najčešće probleme alkoholičara pokazala je da su najčešći problemi: odsustvovanje sa posla, ozbiljne svađe sa supružnikom, fizički napad u pijanom stanju i vožnja pod dejstvom alkohola.</p>

<h2>Količina alkohola</h2>
<p>Koliko pića je normalno piti u toku dana ili nedelje?</p>
<p>Različite organizacije i države postavljaju različite standarde toga koliko pića je normalno. Ovi standardi nastali su kao proizvod istraživanja i ponašanja populacije kojoj su namenjeni.</p>
<SquareImage src={FifthImage} alt="Description">
<p>Opšte preporuke po mojoj interpretaciji, a zasnovano na preporukama svih organizacija su :</p>
<ul>
    <li>Za muškarce: manje od 20g čistog alkohola dnevno, što je oko 2 pića dnevno, odnosno 14 pića nedeljno.</li>
    <li>Za žene: manje od 10g čistog alkohola dnevno, što je oko 1 piće dnevno, odnosno 7 pića nedeljno.</li>
    <li>Mlađi od 21 godine, a možda čak i 23 godine ne bi trebalo da unose alkohol. Postoji niz problema koji specifično utiču na ljude u razvojnom periodu o kojima u ovom tekstu nisam pisao i kojima bi trebalo posvetiti čitavu objavu.</li>
    <li>Trudnice i dojilje ne bi trebalo da unose alkohol zbog izrazito negativnih efekata na fetus i bebu. Alkohol u trudnoći ne samo da može dovesti do pobačaja i mrtvorođenčadi, već može izazvati veći broj celoživotnih poremećaja koji su klasifikovani kao <strong>F</strong>etalni <strong>A</strong>lkoholni <strong>S</strong>pektar <strong>P</strong>oremećaja (FASD).</li>
</ul>
</SquareImage>
<p><a href="https://knowledge4policy.ec.europa.eu/health-promotion-knowledge-gateway/national-low-risk-drinking-recommendations-drinking-guidelines_en">Preporuke Evropske unije</a> se razlikuju od države do države. Primera radi, za Irsku, preporuke su do 17 standardnih pića nedeljno za muškarce, do 11 standardnih pića za žene i barem dva dana nedeljno bez alkohola. Sa druge strane, preporuke za Francusku su ne više od 10 standardnih pića nedeljno i ne više od 2 pića dnevno sa barem jednim danom u nedelji bez alkohola.</p>
<p>Klasifikacija <a href="https://www.niaaa.nih.gov/alcohol-health/overview-alcohol-consumption/moderate-binge-drinking">američkog Nacionalnog instituta za zdravlje</a> je:</p>
<ul>
    <li>Umerena konzumacija alkohola - Ispijanje 2 pića dnevno za muškarce i 1 piće za žene.</li>
    <li>Opijanje - Dovođenje alkohola u krv do 0,8 promila, što je oko 5 pića za muškarce i 4 pića za žene u periodu od oko 2 sata.</li>
    <li>Preterana upotreba alkohola - Konzumacija 5 ili više pića u toku dana ili 15 i više pića u nedelji za muškarce i 4 ili više pića u toku dana ili 8 i više pića u toku nedelje za žene.</li>
</ul>
<p><a href="https://www.nhs.uk/live-well/alcohol-advice/calculating-alcohol-units/">Nacionalna služba za zdravlje Velike Britanije</a> preporučuje unos manje od 14 pića nedeljno i nekoliko dana bez alkohola.</p>
<Conclusion>Različite organizacije postavljaju standarde za „normalnu“ konzumaciju alkohola, a većina preporuka za muškarce iznosi oko 2 pića dnevno (do 14 nedeljno) i za žene oko 1 piće dnevno (do 7 nedeljno), uz više dana bez alkohola tokom nedelje.</Conclusion>
<h2>Zaključak</h2>
<p>Alkohol može da nam pomogne da se opustimo, upoznamo nove ljude i lakše se snađemo u novom socijalnom okruženju. Konzumacija alkohola ima nekoliko zdravstvenih prednosti, ali su negativni efekti po zdravlje više izraženi. Umerena količina alkohola je 0 do 14 pića u toku nedelje, nezavisno od tipa pića. Ako uživate u alkoholu, u redu je, samo nemojte da preterujete.</p>

<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost8