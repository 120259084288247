import React, { Component } from 'react'
import Header from '../common/header'
import IntroForm from './import_gform'

class Forms extends Component {
    render() {
        return (
            <div style={{ maxHeight: '100%',maxWidth:'99%', alignSelf:'center'}}>
                <Header/>
                <div >
                <IntroForm />
                </div>
            </div>
        )
    }
}

export default Forms